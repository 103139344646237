import { message as msg } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { BACKEND_URL } from '../../../Action/types';

const Contact = () => {
  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [subject, setsubject] = useState('');
  const [message, setmessage] = useState('');

  const sendMail = async () => {
    if (email !== '') {
      const config = {
        'Content-Type': 'application/json',
      };
      const body = { name, email, subject, message, query_type: 'contact us' };
      await axios({
        method: 'POST',
        url: BACKEND_URL + 'customer/query',
        data: body,
        headers: config,
      })
        .then((response) => {
          if (response.data.success) {
            msg.success('Query submitted. Will get back to you soon.');
            setname('');
            setemail('');
            setmessage('');
            setsubject('');
          } else {
            msg.success('Something went wrong! Please try again.');
          }
        })
        .catch((error) => {
          msg.success('Something went wrong! Please try again.');
        });
    } else {
      msg.error('Please add email');
    }
  };
  return (
    <div>
      <div className='bg-default-2 pt-12 pb-12 pt-lg-12 pb-lg-12'>
        <div className='container'>
          <div className='row justify-content-center mt-14'>
            <div className='col-xxl-6 col-xl-7 col-lg-8'>
              <h2 className='font-size-9 text-center mb-11'>Contact Us</h2>
              <div className='bg-white px-9 pt-9 pb-7 shadow-8 rounded-4'>
                <form>
                  <div className='row'>
                    <div className='col-12 mb-7'>
                      <label
                        
                        className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'
                      >
                        Your Name
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Jhon Doe'
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </div>
                    <div className='col-lg-6 mb-7'>
                      <label
                        
                        className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'
                      >
                        E-mail
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='example@gmail.com'
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>
                    <div className='col-lg-6 mb-7'>
                      <label
                        
                        className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'
                      >
                        Subject
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Special contract'
                        value={subject}
                        onChange={(e) => setsubject(e.target.value)}
                      />
                    </div>
                    <div className='col-lg-12 mb-7'>
                      <label
                        for='message'
                        className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'
                      >
                        Message
                      </label>
                      <textarea
                        name=''
                        id=''
                        placeholder='Type your message'
                        className='form-control h-px-144'
                        value={message}
                        onChange={(e) => setmessage(e.target.value)}
                      ></textarea>
                    </div>
                    <div className='col-lg-12 pt-4'>
                      <button
                        type='button'
                        className='btn btn-primary text-uppercase w-100 h-px-48'
                        onClick={(e) => {
                          e.preventDefault();
                          sendMail();
                        }}
                      >
                        Send Now
                      </button>
                    </div>
                  </div>
                </form>
                <div className='mt-8'>
                  <h3 className='font-size-4'>Contact Information</h3>
                  <div className='media mb-2'>
                    <div className='mr-6'>
                      <i className='fas fa-phone-alt mt-2'></i>
                    </div>
                    <p className='font-size-4 mb-0'>
                      <a
                        className='mb-0 font-size-4  '
                        href='tel:+917972869198'
                      >
                        +91(INDIA)-7972869198
                      </a>
                    </p>
                  </div>
                  <div className='media mb-2'>
                    <div className='mr-6'>
                      <i className='fas fa-envelope mt-2'></i>
                    </div>
                    <p className='font-size-4 mb-0'>
                      <a
                        className='mb-0 font-size-4 mb-2 '
                        href='mailto:client.success@sigmatext.ai'
                      >
                        client.success@sigmatext.ai
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
