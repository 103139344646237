import React, { useState, useRef, useEffect } from "react";
import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { AudioOutlined, DeleteFilled } from "@ant-design/icons";
import "./AudioRecorder.css";
import { AudioPlayer } from "react-audio-player-component";

const AudioRecorder = ({ setqueryText, setAudioData, audioData }) => {
  const [recordState, setRecordState] = useState(RecordState.NONE);
  const [audioButton, setAudioButton] = useState(false);
  const [showRecorder, setshowRecorder] = useState(0);
  const audioRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {}, [audioButton]);

  const startRecording = () => {
    setRecordState(RecordState.START);
    setAudioButton(true);
    setqueryText("");
    setshowRecorder(1);
  };

  const stopRecording = () => {
    setRecordState(RecordState.STOP);
    setAudioButton(false);
    stopTimer();
    setshowRecorder(2);
  };

  const stopTimer = () => {
    clearInterval(timerRef.current);
  };

  const onStop = async (audioData) => {
    setAudioData(audioData);
    setRecordState(RecordState.NONE);
  };

  const deleteRecording = () => {
    setAudioData(null);
    setshowRecorder(0);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  useEffect(() => {
    if (audioData === null) {
      setshowRecorder(0);
    }
  }, [audioData]);

  return (
    <div className="audio-recorder">
      <div className="controls" style={{ width: "100%" }}>
        <div style={{ display: showRecorder < 2 ? "" : "none" }}>
          <div
            style={{
              width: "calc(100%)",
              visibility: showRecorder === 0 ? "hidden" : "", // Use visibility
            }}
          >
            <AudioReactRecorder
              backgroundColor="#fff"
              state={recordState}
              foregroundColor="#1677ff"
              canvasHeight={18}
              onStop={onStop}
            />
          </div>
        </div>
        {audioData && (
          <div
            className="AudioPlayerClasss"
            style={{
              width: "calc(100% - 44px)",
              visibility: audioButton ? "hidden" : "visible", // Use visibility
              height: "30px",
            }}
          >
            <AudioPlayer
              src={audioData.url}
              minimal={true}
              width={250}
              trackHeight={14}
              barWidth={3}
              gap={1}
              visualise={true}
              backgroundColor="transparent"
              barColor="#89b3f0"
              barPlayedColor="#1677ff"
              skipDuration={2}
              showLoopOption={true}
              showVolumeControl={true}
              hideSeekBar={true}
              hideSeekKnobWhenPlaying={true}
            />
          </div>
        )}
        <div style={{ width: "44px" }}>
          {audioData === null &&
            (audioButton ? (
              <span
                className="control-button px-0 pb-0"
                onClick={stopRecording}
                style={{ paddingTop: "9px" }}
              >
                <i
                  className="fa fa-stop-circle text-danger"
                  style={{ fontSize: "18px" }}
                />
              </span>
            ) : (
              <span
                className="control-button px-0 pb-0"
                onClick={startRecording}
                style={{ paddingTop: "4px", display: "inline-block" }}
              >
                <AudioOutlined style={{ fontSize: "18px" }} />
              </span>
            ))}
          {audioData && (
            <div className="controls" style={{}}>
              <span
                className="control-button  px-0 pb-0"
                onClick={deleteRecording}
                style={{ paddingTop: "4px", display: "inline-block" }}
              >
                <DeleteFilled
                  style={{ fontSize: "18px" }}
                  className="text-danger"
                />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudioRecorder;
