import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { login } from "../../Api/Auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Login = ({
  showModal,
  setModal,
  login,
  isError,
  isErrorType,
  isAuthenticated,
  errMessage,
  setshowModal2,
  setshowModal3,
  setshowModal4,
}) => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  useEffect(() => {
    if (isError) {
      // message.error(errMessage);
    }
  }, [isError, errMessage]);

  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated && showModal) {
      if (window.location.pathname.includes("/education")) {
        history.push("/education/dashboard");
      } else {
        history.push("/dashboard");
      }
    }
  }, [isAuthenticated, showModal, history]);

  useEffect(() => {
    if (isAuthenticated) {
      setModal(false);
    }
  }, [isAuthenticated, setModal]);

  const [showPass, setshowPass] = useState(false);

  return (
    <div>
      <Modal size="md" show={showModal}>
        <Modal.Header className="text-center">
          <h3 className="font-size-6 mb-0 mx-auto text-center">Login</h3>
          <button
            type="button"
            className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
            data-dismiss="modal"
            onClick={() => {
              setModal();
            }}
          >
            <i className="fas fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="row no-gutters">
              <div className="col-lg-12 col-md-12">
                <div className="bg-white-2 h-100 px-11 pt-8 pb-7">
                  <form>
                    <div className="form-group">
                      <label className="font-size-4 text-black-2 font-weight-semibold line-height-reset">
                        E-mail
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        className="form-control"
                        placeholder="example@gmail.com"
                        id="email"
                      />
                    </div>
                    <div className="form-group">
                      <label className="font-size-4 text-black-2 font-weight-semibold line-height-reset">
                        Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={showPass ? "text" : "password"}
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          className="form-control"
                          placeholder="Enter password"
                        />
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            setshowPass(!showPass);
                          }}
                          className={
                            showPass
                              ? "show-password show pos-abs-cr fas mr-6 text-black-2"
                              : "show-password pos-abs-cr fas mr-6 text-black-2"
                          }
                          data-show-pass="password"
                        >
                          {""}
                        </a>
                      </div>
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-between">
                      <a
                        href="#!"
                        className="font-size-3 text-dodger line-height-reset ml-auto"
                        onClick={(e) => {
                          e.preventDefault();
                          setModal(false);
                          setshowModal4(true);
                        }}
                      >
                        Forget Password
                      </a>
                    </div>
                    <div className="form-group mb-8">
                      <button
                        className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          if (email !== "" && password !== "") {
                            login(email, password);
                          }
                        }}
                      >
                        Log in{" "}
                      </button>
                    </div>
                    <p className="font-size-4 text-center heading-default-color">
                      Don’t have an account?{" "}
                      <a
                        href="#!"
                        className="text-primary"
                        onClick={(e) => {
                          setModal(false);
                          setshowModal2(true);
                        }}
                      >
                        Create a free account
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.any,
  isError: PropTypes.any,
  isErrorType: PropTypes.any,
  errMessage: PropTypes.any,
  isAuthenticated: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isError: state.auth.isError,
  isErrorType: state.auth.isErrorType,
  errMessage: state.auth.errMessage,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { login })(Login);
