export const BACKEND_URL = "https://core.sigmatext.ai/";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SUCCESS_GUEST = "LOGIN_SUCCESS_GUEST";
export const LOGIN_FAILED_GUEST = "LOGIN_FAILED_GUEST";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const LOGIN_RESET = "LOGIN_RESET";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const REMOVE_LOGIN = "REMOVE_LOGIN";
export const GUEST_FALSE = "GUEST_FALSE";
export const NEXTCLOUD_UPDATE = "NEXTCLOUD_UPDATE";

//upload
export const ALERT_UPLOAD = "ALERT_UPLOAD";
export const ALERT_CHAT = "ALERT_UPLOAD";
export const UPLOADING_FILE = "UPLOADING_FILE";
export const RESET_UPLOAD = "RESET_UPLOAD";
export const RESET_CHAT = "RESET_CHAT";
export const SET_FILE_ID = "SET_FILE_ID";
export const SET_FILE_DATA = "SET_FILE_DATA";
export const TRIGGER_CONVERSATION = "TRIGGER_CONVERSATION";
export const TYPING_MESSAGE = "TYPING_MESSAGE";
export const UPLOADING_MULTI_FILES = "UPLOADING_MULTI_FILES";
export const CONVERSATION_DATA = "CONVERSATION_DATA";
export const SEARCH_DATA = "SEARCH_DATA";
