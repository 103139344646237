import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BACKEND_URL } from "../../Action/types";
import axios from "axios";
import { message } from "antd";

const Login = ({
  showModal,
  setModal,
  isError,
  isAuthenticated,
  errMessage,
  setshowModal1,
  setshowModal3,
}) => {
  const [email, setemail] = useState("");

  const submit = async () => {
    const config = {
      "Content-Type": "application/json",
    };
    await axios({
      method: "GET",
      url: BACKEND_URL + "auth/reset_password?email=" + email,
      headers: config,
    })
      .then(async (response) => {
        if (response.data.success) {
          message.success(
            "Email with reset link is been sent to you email address."
          );
          setModal(false);
        } else {
          message.error("Something went wrong please try again.");
        }
      })
      .catch((error) => {
        message.error("Something went wrong please try again.");
      });
  };

  useEffect(() => {
    if (isError) {
      // message.error(errMessage);
    }
  }, [isError, errMessage]);

  useEffect(() => {
    if (isAuthenticated) {
      setModal(false);
    }
  }, [isAuthenticated, setModal]);

  return (
    <div>
      <Modal size="md" show={showModal}>
        <Modal.Header className="text-center">
          <h3 className="font-size-6 mb-0 mx-auto text-center">
            Forgot Password
          </h3>
          <button
            type="button"
            className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
            data-dismiss="modal"
            onClick={() => {
              setModal();
            }}
          >
            <i className="fas fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="row no-gutters">
              <div className="col-lg-12 col-md-12">
                <div className="bg-white-2 h-100 px-11 pt-8 pb-7">
                  <form>
                    <div className="form-group">
                      <label className="font-size-4 text-black-2 font-weight-semibold line-height-reset">
                        E-mail
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        className="form-control"
                        placeholder="example@gmail.com"
                        id="email"
                      />
                    </div>
                    <div className="form-group mb-8">
                      <button
                        className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          if (email !== "") {
                            submit();
                          }
                        }}
                      >
                        Proceed{" "}
                      </button>
                    </div>
                    <p className="font-size-4 text-center heading-default-color">
                      Know you password?{" "}
                      <a
                        href="#!"
                        className="text-primary"
                        onClick={(e) => {
                          setModal(false);
                          setshowModal1(true);
                        }}
                      >
                        Login
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.any,
  isError: PropTypes.any,
  isErrorType: PropTypes.any,
  errMessage: PropTypes.any,
  isAuthenticated: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isError: state.auth.isError,
  isErrorType: state.auth.isErrorType,
  errMessage: state.auth.errMessage,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, {})(Login);
