import { Button, Card, Input, message, Popover, Switch, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import { SendOutlined, DownOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import store from "../../../store";
import { CONVERSATION_DATA, TYPING_MESSAGE } from "../../../Action/types";
import { useEffect } from "react";
import { chatFile, listSearchResults } from "../../../Api/Upload";
import { Link } from "react-router-dom";
// import SpeechRecognitionComponent from "./Audiotext";
import AudioRecorder from "./audioRecorder";
import { AudioPlayer } from "react-audio-player-component";

const formatText = (text) => {
  text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

  const htmlString = text
    .replace(/\n/g, "<br />")
    .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") // Replace tab with four non-breaking spaces
    .replace(/ {2,}/g, (match) =>
      match
        .split("")
        .map(() => "&nbsp;")
        .join("")
    ); // Replace multiple spaces with non-breaking spaces
  return { __html: htmlString };
};

function extractAfterFirstQuestion(text) {
  const keyword = "Question: ";
  const firstIndex = text.indexOf(keyword);

  if (firstIndex === -1) {
    return ""; // Return an empty string if the keyword is not found
  }

  return text.slice(firstIndex + keyword.length).trim();
}

var promptTextGen = `Please ignore all previous instructions. You are an expert copywriter who writes detailed and thoughtful content.   I want you to write in the english language. Please use the details provided by the user. Please intersperse short and long sentences. Utilize uncommon terminology to enhance the originality of the content. Please format the content in a professional format. Do not self reference. Do not explain what you are doing.`;

const Index = ({
  data,
  chatFile,
  isError,
  isErrorType,
  errMessage,
  typing,
  conversationData,
  listSearchResults,
  SearchData,
  tourRef4,
  tourRef3,
  currentDirPath,
  setcurrentDirPath,
  prompts,
  setprompts,
  selectePrompts,
  selectePromptsDesc,
}) => {
  useEffect(() => {
    if (isError && isErrorType === "SUCCESS") {
      message.success(errMessage);
    } else if (isError && isErrorType === "FAIL") {
      message.error(errMessage);
    }
  }, [isError, isErrorType, errMessage]);

  const [queryText, setqueryText] = useState("");
  const [searchText, setsearchText] = useState("");
  const [selectedView, setselectedView] = useState("chat");
  const [isGeneric, setisGeneric] = useState(false);
  const currentCursor = useRef(null);
  const [audioData, setAudioData] = useState(null);

  useEffect(() => {
    if (currentCursor.current) {
      const inputElement = currentCursor.current.input;
      inputElement.setSelectionRange(queryText.length, queryText.length);
    }
  }, [queryText]);

  const submitText = async () => {
    if (!typing) {
      if (queryText !== "") {
        let qText = queryText;
        let data = { ...conversationData };
        if (selectedView === "textGeneration") {
          qText = promptTextGen + " Question: " + qText;
        }
        data.conversation.push({
          date: new Date(),
          text: queryText,
          mode: "outgoing",
        });
        store.dispatch({
          type: CONVERSATION_DATA,
          payload: data,
        });
        chatFile(
          conversationData,
          qText,
          isGeneric,
          selectedView !== "textGeneration",
          true,
          false
        );
        setqueryText("");
      }
      if (audioData !== null) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result.split(",")[1];
          let data = { ...conversationData };
          data.conversation.push({
            date: new Date(),
            text: base64Data,
            isAudio: true,
            mode: "outgoing",
          });
          store.dispatch({
            type: CONVERSATION_DATA,
            payload: data,
          });
          setTimeout(() => {
            store.dispatch({ type: TYPING_MESSAGE, payload: true });
          }, 500);
          chatFile(
            conversationData,
            base64Data,
            isGeneric,
            selectedView !== "textGeneration",
            true,
            true
          );
          setAudioData(null);
        };
        reader.readAsDataURL(audioData.blob);
      }
    }
  };

  const submitSearch = () => {
    if (searchText !== "") {
      listSearchResults(conversationData, searchText);
      setsearchText("");
    }
  };

  const heightRef = useRef(null);
  // const [searchEnabled, setsearchEnabled] = useState(false);

  useEffect(() => {
    if (heightRef.current !== null) {
      var objDiv = heightRef.current;
      objDiv.scrollTop = objDiv.scrollHeight + 1000;
    }
  }, [conversationData, selectedView]);

  useEffect(() => {
    if (heightRef.current !== null && typing) {
      var objDiv = heightRef.current;
      objDiv.scrollTop = objDiv.scrollHeight + 1000;
    }
  }, [typing]);

  //prompt textGeneration

  return (
    <div className="dashboard-main-container mt-9 mt-lg-9" id="dashboard-body">
      <div className="container position-relative">
        <span
          style={{
            position: "absolute",
            fontSize: "14px",
            fontWeight: "500",
            top: "-25px",
            left: "5%",
          }}
        >
          /My Files{currentDirPath}
          {conversationData === null
            ? ""
            : conversationData.is_file && "/" + conversationData.file}
        </span>
        <span
          style={{
            position: "absolute",
            fontSize: "14px",
            fontWeight: "500",
            top: "-25px",
            right: "5%",
          }}
        >
          <span className="mr-3">Use External Knowledge: </span>
          <Tooltip
            title={
              isGeneric
                ? "Disable External Knowledge base"
                : "Enable External Knowledge base"
            }
          >
            <Switch
              size="small"
              onChange={() => setisGeneric(!isGeneric)}
              checked={isGeneric}
            />
          </Tooltip>
        </span>
        <div className="row">
          <div className="col-11 m-auto heightFull">
            <div
              ref={tourRef4}
              className="col-12 m-auto px-14 inputBox position-relative"
            >
              <Input
                placeholder="Enter your query here..."
                ref={currentCursor}
                style={{ fontSize: "15px", height: "45px" }}
                className="pl-0"
                prefix={
                  <Popover
                    content={
                      <div style={{ width: "160px", fontSize: "12px" }}>
                        <p
                          style={{
                            fontSize: "14px",
                            padding: "7px 10px",
                            marginBottom: "0px",
                            cursor: "pointer",
                          }}
                          onMouseEnter={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.target.style.background = "#f5f5f5";
                          }}
                          onMouseLeave={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.target.style.background = "transparent";
                          }}
                          onClick={() => {
                            setselectedView("chat");
                            setsearchText("");
                            setqueryText("");
                            setAudioData(null);
                          }}
                        >
                          Chat
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            padding: "7px 10px",
                            marginBottom: "0px",
                            cursor: "pointer",
                          }}
                          onMouseEnter={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.target.style.background = "#f5f5f5";
                          }}
                          onMouseLeave={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.target.style.background = "transparent";
                          }}
                          onClick={() => {
                            setselectedView("search");
                            setprompts(false);
                            setsearchText("");
                            setqueryText("");
                            setAudioData(null);
                          }}
                        >
                          Search
                        </p>
                        <p
                          style={{
                            fontSize: "14px",
                            padding: "7px 10px",
                            marginBottom: "0px",
                            cursor: "pointer",
                          }}
                          onMouseEnter={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.target.style.background = "#f5f5f5";
                          }}
                          onMouseLeave={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            e.target.style.background = "transparent";
                          }}
                          onClick={() => {
                            setselectedView("textGeneration");
                            setprompts(true);
                            setsearchText("");
                            setqueryText("");
                            setAudioData(null);
                          }}
                        >
                          Text Generation
                        </p>
                      </div>
                    }
                    trigger="click"
                  >
                    <Button
                      type="primary"
                      className="px-7 mr-2"
                      style={{ height: "45px", width: "170px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {selectedView === "chat"
                        ? "Chat"
                        : selectedView === "search"
                        ? "Search"
                        : "Text Generation"}
                      <DownOutlined
                        style={{
                          position: "relative",
                          top: "-3px",
                        }}
                      />
                    </Button>
                  </Popover>
                  // <Select
                  //   style={{ width: "140px", zIndex: "999" }}
                  //   value={selectedView}
                  //   onClick={(e) => {
                  //     e.preventDefault();
                  //     e.stopPropagation();
                  //   }}
                  //   onChange={(e) => {
                  //     setselectedView(e);
                  //     if (e === "sentiment") {
                  //       setprompts(true);
                  //     } else {
                  //       setprompts(false);
                  //     }
                  //   }}
                  // >
                  //   <Select.Option value="chat">Chat</Select.Option>
                  //   <Select.Option value="search">Search</Select.Option>
                  //   <Select.Option value="sentiment">
                  //     Sentiment Analyse
                  //   </Select.Option>
                  // </Select>
                }
                suffix={
                  <SendOutlined
                    className=" text-primary"
                    style={{ fontSize: "18px", paddingLeft: "32px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (selectedView === "chat") {
                        submitText();
                      } else if (selectedView === "search") {
                        submitSearch();
                      } else {
                        submitText();
                      }
                    }}
                  />
                }
                value={selectedView === "search" ? searchText : queryText}
                disabled={
                  data.length > 0
                    ? conversationData === null
                      ? true
                      : conversationData.is_folder
                      ? conversationData.value !== ""
                        ? conversationData.processedCount === 0 &&
                          conversationData.data.length === 0
                        : false
                      : !conversationData.isProcessed
                    : true
                }
                onChange={(e) => {
                  if (selectedView === "chat") {
                    setqueryText(e.target.value);
                  } else if (selectedView === "search") {
                    setsearchText(e.target.value);
                  } else {
                    setqueryText(e.target.value);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (selectedView === "chat") {
                      submitText();
                    } else if (selectedView === "search") {
                      submitSearch();
                    } else {
                      submitText();
                    }
                  }
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "1px",
                  right: "82px",
                  zIndex: "999",
                  width: "40%",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setqueryText("");
                }}
              >
                <AudioRecorder
                  setqueryText={setqueryText}
                  audioData={audioData}
                  setAudioData={setAudioData}
                />
                {/* <SpeechRecognitionComponent setqueryText={setqueryText} /> */}
              </div>
            </div>
            {selectedView === "chat" || selectedView === "textGeneration" ? (
              <Card bodyStyle={{ paddingBottom: "4px" }}>
                <div className="chat-history" ref={heightRef}>
                  {conversationData !== null && (
                    <ul className="m-b-0">
                      {conversationData.hasOwnProperty("conversation") &&
                        conversationData.conversation.map((ele, index) =>
                          ele.mode === "outgoing" ? (
                            <li className="clearfix" key={index}>
                              <div className="message-data text-right">
                                <span className="message-data-time">
                                  {moment(ele.date).format("DD-MM-YYYY hh:mm")}
                                </span>
                                <img
                                  src="https://bootdey.com/img/Content/avatar/avatar7.png"
                                  alt="avatar"
                                />
                              </div>
                              <div className="message other-message float-right text-left">
                                {ele.hasOwnProperty("isAudio") &&
                                ele.isAudio ? (
                                  <div className="AudioPlayerClasss">
                                    <AudioPlayer
                                      src={"data:audio/wav;base64," + ele.text}
                                      minimal={true}
                                      width={250}
                                      trackHeight={14}
                                      barWidth={3}
                                      gap={1}
                                      visualise={true}
                                      backgroundColor="transparent"
                                      barColor="#89b3f0"
                                      barPlayedColor="#1677ff"
                                      skipDuration={2}
                                      showLoopOption={true}
                                      showVolumeControl={true}
                                      hideSeekBar={true}
                                      hideSeekKnobWhenPlaying={true}
                                    />
                                  </div>
                                ) : ele.text.includes(promptTextGen) ? (
                                  extractAfterFirstQuestion(ele.text)
                                ) : (
                                  ele.text
                                )}
                              </div>
                            </li>
                          ) : (
                            <li className="clearfix" key={index}>
                              <div className="message-data">
                                <span className="message-data-time">
                                  {moment(ele.date).format("DD-MM-YYYY hh:mm")}
                                </span>
                              </div>
                              <div
                                className="message my-message"
                                dangerouslySetInnerHTML={formatText(ele.text)}
                              ></div>
                            </li>
                          )
                        )}
                      {typing && (
                        <li className="clearfix">
                          <div className="message-data">
                            <span className="message-data-time"></span>
                          </div>
                          <div
                            className="message my-message"
                            style={{ height: "30px" }}
                          >
                            <img
                              src="/assets/image/typing.gif"
                              style={{ height: "35px", marginTop: "-28px" }}
                              alt=""
                            />
                          </div>
                        </li>
                      )}
                      {data.length > 0 &&
                      conversationData.hasOwnProperty("isProcessed") &&
                      conversationData.is_folder
                        ? conversationData.processedCount === 0 && (
                            <li className="clearfix">
                              <div className="message-data">
                                <span className="message-data-time"></span>
                              </div>
                              <div className="message my-message">
                                Processing your document(s)...
                              </div>
                            </li>
                          )
                        : data.length > 0 &&
                          !conversationData.isProcessed && (
                            <li className="clearfix">
                              <div className="message-data">
                                <span className="message-data-time"></span>
                              </div>
                              <div className="message my-message">
                                Processing your document(s)...
                              </div>
                            </li>
                          )}
                    </ul>
                  )}
                </div>
              </Card>
            ) : (
              <Card bodyStyle={{ paddingBottom: "4px" }}>
                <div className="chat-history search-history pr-2">
                  <ul className="m-b-0">
                    {SearchData.map((ele, index) => (
                      <li className="clearfix mb-4" key={index}>
                        <Link
                          to="#!"
                          style={{
                            fontWeight: "600",
                            cursor: "pointer",
                            fontSize: "15px",
                          }}
                          className="pb-0 mb-0"
                        >
                          {ele.file_path}
                          {ele.page_number !== "None" && " --- "}
                          {ele.page_number !== "None" && (
                            <span style={{ fontWeight: "normal" }}>
                              (Page Number: {ele.page_number} )
                            </span>
                          )}
                        </Link>
                        <Popover
                          placement="topLeft"
                          content={
                            <div
                              className="pb-0 mb-0"
                              style={{
                                width: "100%",
                                height: "40vh",
                                overflow: "auto",
                              }}
                              dangerouslySetInnerHTML={formatText(
                                ele.page_content
                              )}
                            ></div>
                          }
                        >
                          <p style={{ fontSize: "14px" }} className="pb-0 mb-0">
                            {ele.page_content.substring(0, 400) + "..."}
                          </p>
                        </Popover>
                      </li>
                    ))}
                  </ul>
                </div>
              </Card>
            )}
          </div>
        </div>
        <div className="row"></div>
      </div>
    </div>
  );
};

Index.propTypes = {
  isError: PropTypes.any,
  isErrorType: PropTypes.any,
  errMessage: PropTypes.any,
  isAuthenticated: PropTypes.any,
  data: PropTypes.any.isRequired,
  typing: PropTypes.any,
  conversationData: PropTypes.any,
  listSearchResults: PropTypes.any,
  SearchData: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isError: state.upload.isErrorChat,
  isErrorType: state.upload.isErrorTypeChat,
  errMessage: state.upload.errMessageChat,
  data: state.upload.data,
  typing: state.upload.typing,
  isAuthenticated: state.auth.isAuthenticated,
  conversationData: state.upload.conversationData,
  SearchData: state.upload.SearchData,
});
export default connect(mapStateToProps, { chatFile, listSearchResults })(Index);
