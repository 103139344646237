import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { login } from "../../Api/Auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from "antd";
import axios from "axios";
import { BACKEND_URL } from "../../Action/types";
import { useHistory } from "react-router-dom";

const Login = ({
  showModal,
  setModal,
  login,
  isError,
  isErrorType,
  isAuthenticated,
  errMessage,
  setshowModal2,
  token,
  settoken,
}) => {
  const [password, setpassword] = useState("");
  const [passwordCheck, setpasswordCheck] = useState("");
  const history = useHistory();

  const submit = async () => {
    if (window.location.pathname.includes("education")) {
      history.push("/education");
    } else {
      history.push("/");
    }
    var tok = isAuthenticated ? sessionStorage.getItem("token") : token;
    const config = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + tok,
    };
    await axios({
      method: "PUT",
      url: BACKEND_URL + "auth/reset_password",
      headers: config,
      data: { token: token, password: password },
    })
      .then(async (response) => {
        if (response.data.success) {
          message.success("Password reset successfully.");
          setModal(false);
        } else {
          message.error("Something went wrong please try again.");
        }
      })
      .catch((error) => {
        message.error("Something went wrong please try again.");
      });
  };

  useEffect(() => {
    if (isError) {
      // message.error(errMessage);
    }
  }, [isError, errMessage]);

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     setModal(false);
  //   }
  // }, [isAuthenticated, setModal]);

  const [showPass, setshowPass] = useState(false);
  const [showPass1, setshowPass1] = useState(false);

  return (
    <div>
      <Modal size="md" show={showModal}>
        <Modal.Header className="text-center">
          <h3 className="font-size-6 mb-0 mx-auto text-center">
            Reset Password
          </h3>
          <button
            type="button"
            className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
            data-dismiss="modal"
            onClick={() => {
              setModal();
            }}
          >
            <i className="fas fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="row no-gutters">
              <div className="col-lg-12 col-md-12">
                <div className="bg-white-2 h-100 px-11 pt-8 pb-7">
                  <form>
                    <div className="form-group">
                      <label className="font-size-4 text-black-2 font-weight-semibold line-height-reset">
                        New Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={showPass ? "text" : "password"}
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          className="form-control"
                          placeholder="Enter password"
                        />
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            setshowPass(!showPass);
                          }}
                          className={
                            showPass
                              ? "show-password show pos-abs-cr fas mr-6 text-black-2"
                              : "show-password pos-abs-cr fas mr-6 text-black-2"
                          }
                          data-show-pass="password"
                        >
                          {""}
                        </a>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="font-size-4 text-black-2 font-weight-semibold line-height-reset">
                        Confirm Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={showPass1 ? "text" : "password"}
                          value={passwordCheck}
                          onChange={(e) => setpasswordCheck(e.target.value)}
                          className="form-control"
                          placeholder="Enter password"
                        />
                        <a
                          href="#!"
                          onClick={(e) => {
                            e.preventDefault();
                            setshowPass1(!showPass1);
                          }}
                          className={
                            showPass1
                              ? "show-password show pos-abs-cr fas mr-6 text-black-2"
                              : "show-password pos-abs-cr fas mr-6 text-black-2"
                          }
                          data-show-pass="password"
                        >
                          {""}
                        </a>
                      </div>
                    </div>
                    <div className="form-group mb-8">
                      <button
                        className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          if (
                            token !== "" &&
                            password !== "" &&
                            passwordCheck !== ""
                          ) {
                            if (password !== passwordCheck) {
                              message.error("Password Does not match");
                            } else {
                              submit();
                            }
                          } else {
                            message.error("Please fill all the details");
                          }
                        }}
                      >
                        Reset{" "}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.any,
  isError: PropTypes.any,
  isErrorType: PropTypes.any,
  errMessage: PropTypes.any,
  isAuthenticated: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isError: state.auth.isError,
  isErrorType: state.auth.isErrorType,
  errMessage: state.auth.errMessage,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { login })(Login);
