import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className='bg-default-2 pt-16 pt-lg-22 pb-lg-27'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-12 mt-8 dark-mode-texts'>
            <div className='mb-9'>
              <Link className='d-flex align-items-center ml-4' to='/'>
                {' '}
                <i className='icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8'></i>
                <span className='text-uppercase font-size-3 font-weight-bold text-gray'>
                  Back
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className='row '>
          <div className='col-12 col-xl-12 col-lg-12'>
            <div className='bg-white rounded-4 pt-0 shadow-9'>
              <div
                className='tab-content pl-12 pt-10 pb-7 pr-12 pr-xxl-24'
                id='myTabContent'
              >
                <div
                  className='tab-pane fade show active'
                  id='home'
                  role='tabpanel'
                  aria-labelledby='home-tab'
                >
                  <h3 className='font-size-6 mb-7 text-black-2 font-weight-semibold'>
                    Privacy Policy
                  </h3>
                  <div className='pt-5 '>
                    <p className='font-size-4 mb-8'>
                      This Privacy Policy describes Our policies and procedures
                      on the collection, use and disclosure of Your information
                      when You use the Service and tells You about Your privacy
                      rights and how the law protects You. We use Your Personal
                      data to provide and improve the Service. By using the
                      Service, You agree to the collection and use of
                      information in accordance with this Privacy Policy.
                    </p>
                    <h4 className='font-size-5 mb-2 text-black-2 font-weight-semibold'>
                      Interpretation and Definitions
                    </h4>
                    <p className='font-size-4 mb-8'>
                      <strong>Interpretation</strong>
                      <br />
                      The words of which the initial letter is capitalized have
                      meanings defined under the following conditions. The
                      following definitions shall have the same meaning
                      regardless of whether they appear in singular or in
                      plural.
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Definitions</strong>
                      <br />
                      For the purposes of this Privacy Policy:
                      <ul className=''>
                        <li className=''>
                          <span className=''>
                            Account means a unique account created for You to
                            access our Service or parts of our Service.
                          </span>
                        </li>
                        <li className=''>
                          <span className=''>
                            Company (referred to as either "the Company", "We",
                            "Us" or "Our" in this Agreement) refers to
                            Viewoolabs Private Limited, 105, First Floor, Aditya
                            Arcade, New Delhi, India.
                          </span>
                        </li>
                        <li className=''>
                          <span className=''>
                            Cookies are small files that are placed on Your
                            computer, mobile device or any other device by a
                            website, containing the details of Your browsing
                            history on that website among its many uses.
                          </span>
                        </li>
                        <li className=''>
                          <span className=''>
                            Country refers to: Delhi, India
                          </span>
                        </li>
                        <li className=''>
                          <span className=''>
                            Device means any device that can access the Service
                            such as a computer, a cellphone or a digital tablet.
                          </span>
                        </li>
                        <li className=''>
                          <span className=''>
                            Personal Data is any information that relates to an
                            identified or identifiable individual.
                          </span>
                        </li>
                        <li className=''>
                          <span className=''>
                            Service refers to the Website.
                          </span>
                        </li>
                        <li className=''>
                          <span className=''>
                            Service Provider means any natural or legal person
                            who processes the data on behalf of the Company. It
                            refers to third-party companies or individuals
                            employed by the Company to facilitate the Service,
                            to provide the Service on behalf of the Company, to
                            perform services related to the Service or to assist
                            the Company in analyzing how the Service is used.
                          </span>
                        </li>
                        <li className=''>
                          <span className=''>
                            Usage Data refers to data collected automatically,
                            either generated by the use of the Service or from
                            the Service infrastructure itself (for example, the
                            duration of a page visit).
                          </span>
                        </li>
                        <li className=''>
                          <span className=''>
                            Website refers to Momenttext, accessible from&nbsp;
                            <span className=''>
                              <a
                                href='www.momenttext.com'
                                target='_blank'
                                rel='noreferrer noopener'
                                className=''
                              >
                                www.momenttext.com
                              </a>
                            </span>
                          </span>
                        </li>
                        <li className=''>
                          <span className=''>
                            You means the individual accessing or using the
                            Service, or the company, or other legal entity on
                            behalf of which such individual is accessing or
                            using the Service, as applicable.
                          </span>
                        </li>
                      </ul>
                    </p>
                    <h4 className='font-size-5 mb-2 text-black-2 font-weight-semibold'>
                      Collecting and Using Your Personal Data
                    </h4>
                    <p className='font-size-4 mb-8'>
                      <strong>Personal Data</strong>
                      <br />
                      While using Our Service, We may ask You to provide Us with
                      certain personally identifiable information that can be
                      used to contact or identify You. Personally identifiable
                      information may include, but is not limited to:
                      <ul>
                        <li>
                          <span>Email address</span>
                        </li>
                        <li>
                          <span>First name and last name</span>
                        </li>
                        <li>
                          <span>Phone number</span>
                        </li>
                        <li>
                          <span>Usage Data</span>
                        </li>
                      </ul>
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Usage Data</strong>
                      <br />
                      Usage Data is collected automatically when using the
                      Service. Usage Data may include information such as Your
                      Device's Internet Protocol address (e.g. IP address),
                      browser type, browser version, the pages of our Service
                      that You visit, the time and date of Your visit, the time
                      spent on those pages, unique device identifiers and other
                      diagnostic data.
                      <br />
                      When You access the Service by or through a mobile device,
                      We may collect certain information automatically,
                      including, but not limited to, the type of mobile device
                      You use, Your mobile device unique ID, the IP address of
                      Your mobile device, Your mobile operating system, the type
                      of mobile Internet browser You use, unique device
                      identifiers and other diagnostic data. We may also collect
                      information that Your browser sends whenever You visit our
                      Service or when You access the Service by or through a
                      mobile device.
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Tracking Technologies and Cookies</strong>
                      <br />
                      We use Cookies and similar tracking technologies to track
                      the activity on Our Service and store certain information.
                      Tracking technologies used are beacons, tags, and scripts
                      to collect and track information and to improve and
                      analyze Our Service. The technologies We use may include:
                      <ul>
                        <li>
                          Cookies or Browser Cookies. A cookie is a small file
                          placed on Your Device. You can instruct Your browser
                          to refuse all Cookies or to indicate when a Cookie is
                          being sent. However, if You do not accept Cookies, You
                          may not be able to use some parts of our Service.
                          Unless you have adjusted Your browser setting so that
                          it will refuse Cookies, our Service may use Cookies.
                        </li>
                        <li>
                          Web Beacons. Certain sections of our Service and our
                          emails may contain small electronic files known as web
                          beacons (also referred to as clear gifs, pixel tags,
                          and single-pixel gifs) that permit the Company, for
                          example, to count users who have visited those pages
                          or opened an email and for other related website
                          statistics (for example, recording the popularity of a
                          certain section and verifying system and server
                          integrity).
                        </li>
                      </ul>
                      Cookies can be "Persistent" or "Session" Cookies.
                      Persistent Cookies remain on Your personal computer or
                      mobile device when You go offline, while Session Cookies
                      are deleted as soon as You close Your web browser. We use
                      both Session and Persistent Cookies for the purposes set
                      out below:
                      <ul className='font_8 wixui-rich-text__text'>
                        <li>
                          Necessary / Essential Cookies Type: Session Cookies
                          <br />
                          Administered by: Us <br />
                          Purpose: These Cookies are essential to provide You
                          with services available through the Website and to
                          enable You to use some of its features. They help to
                          authenticate users and prevent fraudulent use of user
                          accounts. Without these Cookies, the services that You
                          have asked for cannot be provided, and We only use
                          these Cookies to provide You with those services.
                        </li>
                        <li>
                          Cookies Policy / Notice Acceptance Cookies <br />
                          Type: Persistent Cookies <br />
                          Administered by: Us <br />
                          Purpose: These Cookies identify if users have accepted
                          the use of cookies on the Website.
                        </li>
                        <li>
                          Functionality Cookies Type: Persistent Cookies
                          <br />
                          Administered by: Us <br />
                          Purpose: These Cookies allow us to remember choices
                          You make when You use the Website, such as remembering
                          your login details or language preference. The purpose
                          of these Cookies is to provide You with a more
                          personal experience and to avoid You having to
                          re-enter your preferences every time You use the
                          Website.
                        </li>
                      </ul>
                      For more information about the cookies we use and your
                      choices regarding cookies, please visit our Cookies Policy
                      or the Cookies section of our Privacy Policy.
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Use of Your Personal Data</strong> <br />
                      The Company may use Personal Data for the following
                      purposes:
                      <ul>
                        <li>
                          To provide and maintain our Service, including to
                          monitor the usage of our Service.
                        </li>
                        <li>
                          To manage Your Account: to manage Your registration as
                          a user of the Service. The Personal Data You provide
                          can give You access to different functionalities of
                          the Service that are available to You as a registered
                          user.
                        </li>
                        <li>
                          For the performance of a contract: the development,
                          compliance and undertaking of the purchase contract
                          for the products, items or services You have purchased
                          or of any other contract with Us through the Service.
                        </li>
                        <li>
                          To contact You: To contact You by email, telephone
                          calls, SMS, or other equivalent forms of electronic
                          communication, such as a mobile application's push
                          notifications regarding updates or informative
                          communications related to the functionalities,
                          products or contracted services, including the
                          security updates, when necessary or reasonable for
                          their implementation.
                        </li>
                        <li>
                          To provide You with news, special offers and general
                          information about other goods, services and events
                          which we offer that are similar to those that you have
                          already purchased or enquired about unless You have
                          opted not to receive such information.
                        </li>
                        <li>
                          To manage Your requests: To attend and manage Your
                          requests to Us.
                        </li>
                        <li>
                          For business transfers: We may use Your information to
                          evaluate or conduct a merger, divestiture,
                          restructuring, reorganization, dissolution, or other
                          sale or transfer of some or all of Our assets, whether
                          as a going concern or as part of bankruptcy,
                          liquidation, or similar proceeding, in which Personal
                          Data held by Us about our Service users is among the
                          assets transferred.
                        </li>
                        <li>
                          For other purposes: We may use Your information for
                          other purposes, such as data analysis, identifying
                          usage trends, determining the effectiveness of our
                          promotional campaigns and to evaluate and improve our
                          Service, products, services, marketing and your
                          experience.
                        </li>
                      </ul>
                      We may share Your personal information in the following
                      situations:
                      <ul className='font_8 wixui-rich-text__text'>
                        <li>
                          With Service Providers: We may share Your personal
                          information with Service Providers to monitor and
                          analyze the use of our Service, to contact You.
                        </li>
                        <li>
                          For business transfers: We may share or transfer Your
                          personal information in connection with, or during
                          negotiations of, any merger, sale of Company assets,
                          financing, or acquisition of all or a portion of Our
                          business to another company.
                        </li>
                        <li>
                          With Affiliates: We may share Your information with
                          Our affiliates, in which case we will require those
                          affiliates to honor this Privacy Policy. Affiliates
                          include Our parent company and any other subsidiaries,
                          joint venture partners or other companies that We
                          control or that are under common control with Us.
                        </li>
                        <li>
                          With business partners: We may share Your information
                          with Our business partners to offer You certain
                          products, services or promotions.
                        </li>
                        <li>
                          With other users: when You share personal information
                          or otherwise interact in the public areas with other
                          users, such information may be viewed by all users and
                          may be publicly distributed outside.
                        </li>
                        <li>
                          With Your consent: We may disclose Your personal
                          information for any other purpose with Your consent.
                        </li>
                      </ul>
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Retention of Your Personal Data</strong> <br />
                      The Company will retain Your Personal Data only for as
                      long as is necessary for the purposes set out in this
                      Privacy Policy. We will retain and use Your Personal Data
                      to the extent necessary to comply with our legal
                      obligations (for example, if we are required to retain
                      your data to comply with applicable laws), resolve
                      disputes, and enforce our legal agreements and policies.
                      The Company will also retain Usage Data for internal
                      analysis purposes. Usage Data is generally retained for a
                      shorter period of time, except when this data is used to
                      strengthen the security or to improve the functionality of
                      Our Service, or We are legally obligated to retain this
                      data for longer time periods.
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Transfer of Your Personal Data</strong> <br />
                      Your information, including Personal Data, is processed at
                      the Company's operating offices and in any other places
                      where the parties involved in the processing are located.
                      It means that this information may be transferred to — and
                      maintained on — computers located outside of Your state,
                      province, country or other governmental jurisdiction where
                      the data protection laws may differ than those from Your
                      jurisdiction. <br />
                      Your consent to this Privacy Policy followed by Your
                      submission of such information represents Your agreement
                      to that transfer. The Company will take all steps
                      reasonably necessary to ensure that Your data is treated
                      securely and in accordance with this Privacy Policy and no
                      transfer of Your Personal Data will take place to an
                      organization or a country unless there are adequate
                      controls in place including the security of Your data and
                      other personal information.
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Delete Your Personal Data</strong> <br />
                      You have the right to delete or request that We assist in
                      deleting the Personal Data that We have collected about
                      You. Our Service may give You the ability to delete
                      certain information about You from within the Service.
                      <br /> You may update, amend, or delete Your information
                      at any time by signing in to Your Account, if you have
                      one, and visiting the account settings section that allows
                      you to manage Your personal information. You may also
                      contact Us to request access to, correct, or delete any
                      personal information that You have provided to Us.
                      <br /> Please note, however, that We may need to retain
                      certain information when we have a legal obligation or
                      lawful basis to do so.
                    </p>
                    <h4 className='font-size-5 mb-2 text-black-2 font-weight-semibold'>
                      Disclosure of Your Personal Data
                    </h4>
                    <p className='font-size-4 mb-8'>
                      <strong>Business Transactions</strong>
                      <br />
                      If the Company is involved in a merger, acquisition or
                      asset sale, Your Personal Data may be transferred. We will
                      provide notice before Your Personal Data is transferred
                      and becomes subject to a different Privacy Policy.
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Law enforcement</strong>
                      <br />
                      Under certain circumstances, the Company may be required
                      to disclose Your Personal Data if required to do so by law
                      or in response to valid requests by public authorities
                      (e.g. a court or a government agency).
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Other legal requirements</strong>
                      <br />
                      The Company may disclose Your Personal Data in the good
                      faith belief that such action is necessary to:
                      <ul>
                        <li>Comply with a legal obligation</li>
                        <li>
                          Protect and defend the rights or property of the
                          Company
                        </li>
                        <li>
                          Prevent or investigate possible wrongdoing in
                          connection with the Service
                        </li>
                        <li>
                          Protect the personal safety of Users of the Service or
                          the public
                        </li>
                        <li>Protect against legal liability</li>
                      </ul>
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Security of Your Personal Data</strong>
                      <br />
                      The security of Your Personal Data is important to Us, but
                      remember that no method of transmission over the Internet,
                      or method of electronic storage is 100% secure. While We
                      strive to use commercially acceptable means to protect
                      Your Personal Data, We cannot guarantee its absolute
                      security.
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Children's Privacy</strong>
                      <br />
                      Our Service does not address anyone under the age of 13.
                      We do not knowingly collect personally identifiable
                      information from anyone under the age of 13. If You are a
                      parent or guardian and You are aware that Your child has
                      provided Us with Personal Data, please contact Us. If We
                      become aware that We have collected Personal Data from
                      anyone under the age of 13 without verification of
                      parental consent, We take steps to remove that information
                      from Our servers <br />. If We need to rely on consent as
                      a legal basis for processing Your information and Your
                      country requires consent from a parent, We may require
                      Your parent's consent before We collect and use that
                      information.
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Links to Other Websites</strong>
                      <br />
                      Our Service may contain links to other websites that are
                      not operated by Us. If You click on a third party link,
                      You will be directed to that third party's site. We
                      strongly advise You to review the Privacy Policy of every
                      site You visit.
                      <br />
                      We have no control over and assume no responsibility for
                      the content, privacy policies or practices of any third
                      party sites or services.
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Changes to this Privacy Policy</strong>
                      <br />
                      We may update Our Privacy Policy from time to time. We
                      will notify You of any changes by posting the new Privacy
                      Policy on this page. We will let You know via email and/or
                      a prominent notice on Our Service, prior to the change
                      becoming effective and update the "Last updated" date at
                      the top of this Privacy Policy. <br /> You are advised to
                      review this Privacy Policy periodically for any changes.
                      Changes to this Privacy Policy are effective when they are
                      posted on this page.
                      <br />
                      We have no control over and assume no responsibility for
                      the content, privacy policies or practices of any third
                      party sites or services.
                    </p>
                    <p className='font-size-4 mb-8'>
                      <strong>Contact Us</strong>
                      <br />
                      If you have any questions about this Privacy Policy, You
                      can contact us:
                      <br />
                      By email: client.success@sigmatext.ai
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
