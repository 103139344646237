import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { register } from "../../Api/Auth";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { message } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Login = ({
  showModal,
  setModal,
  register,
  isError,
  isErrorType,
  isAuthenticated,
  errMessage,
  setshowModal,
}) => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (isError) {
      message.error(errMessage);
    }
  }, [isError, errMessage]);

  useEffect(() => {
    if (isAuthenticated) {
      setModal(false);
    }
  }, [isAuthenticated, setModal]);

  useEffect(() => {
    if (!showModal) {
      setemail("");
      setpassword("");
      setfirst_name("");
      setlast_name("");
    }
  }, [showModal]);

  useEffect(() => {
    if (isAuthenticated && showModal) {
      if (window.location.pathname.includes("/education")) {
        history.push("/education/dashboard");
      } else {
        history.push("/dashboard");
      }
    }
  }, [isAuthenticated, showModal, history]);

  const [passType, setpassType] = useState(true);

  return (
    <div>
      <Modal size="md" show={showModal}>
        <Modal.Header className="text-center">
          <h3 className="font-size-6 mb-0 mx-auto text-center">Register</h3>
          <button
            type="button"
            className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
            data-dismiss="modal"
            onClick={() => {
              setModal();
            }}
          >
            <i className="fas fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="login-modal-main bg-white rounded-8 overflow-hidden">
            <div className="row no-gutters">
              <div className="col-lg-12 col-md-12">
                <div className="bg-white-2 h-100 px-8 pt-5 pb-7">
                  <form>
                    <div className="form-group">
                      <label className="font-size-4 text-black-2 font-weight-semibold line-height-reset">
                        First Name
                      </label>
                      <input
                        type="text"
                        value={first_name}
                        onChange={(e) => setfirst_name(e.target.value)}
                        className="form-control"
                        placeholder="Adam "
                        id="first_name"
                      />
                    </div>
                    <div className="form-group">
                      <label className="font-size-4 text-black-2 font-weight-semibold line-height-reset">
                        Last Name
                      </label>
                      <input
                        type="text"
                        value={last_name}
                        onChange={(e) => setlast_name(e.target.value)}
                        className="form-control"
                        placeholder="Warlock"
                        id="name"
                      />
                    </div>
                    <div className="form-group">
                      <label className="font-size-4 text-black-2 font-weight-semibold line-height-reset">
                        E-mail
                      </label>
                      <input
                        type="email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        className="form-control"
                        placeholder="example@gmail.com"
                        id="email"
                      />
                    </div>
                    <div className="form-group">
                      <label className="font-size-4 text-black-2 font-weight-semibold line-height-reset">
                        Password
                      </label>
                      <div className="position-relative">
                        <input
                          type={passType ? "password" : "text"}
                          value={password}
                          onChange={(e) => setpassword(e.target.value)}
                          className="form-control"
                          name="password"
                          placeholder="Enter password"
                        />
                        <a
                          href="#!"
                          className={
                            " pos-abs-cr fas mr-6 text-black-2" +
                            (passType
                              ? " show-password"
                              : " show-password show")
                          }
                          data-show-pass="password"
                          onClick={(e) => setpassType((prev) => !prev)}
                        >
                          {""}
                        </a>
                      </div>
                    </div>
                    <div className="form-group mb-8">
                      <button
                        className="btn btn-primary btn-medium w-100 rounded-5 text-uppercase"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          if (
                            email !== "" &&
                            password !== "" &&
                            first_name !== "" &&
                            last_name !== ""
                          ) {
                            register(email, password, first_name, last_name);
                          } else {
                            message.error("All the fields are required");
                          }
                        }}
                      >
                        Register{" "}
                      </button>
                    </div>
                    <p className="font-size-4 text-center heading-default-color">
                      Already have an account?{" "}
                      <a
                        href="#!"
                        className="text-primary"
                        onClick={(e) => {
                          setModal(false);
                          setshowModal(true);
                        }}
                      >
                        Login
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.any,
  isError: PropTypes.any,
  isErrorType: PropTypes.any,
  errMessage: PropTypes.any,
  isAuthenticated: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isError: state.auth.isError,
  isErrorType: state.auth.isErrorType,
  errMessage: state.auth.errMessage,
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { register })(Login);
