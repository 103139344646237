import React from 'react';
import { Modal } from 'react-bootstrap';

const Login = ({ showModal, setModal, selectedPrice, setselectedPrice }) => {
  return (
    <div>
      <Modal size='xl' show={showModal}>
        <Modal.Header className='text-center'>
          <h3 className='font-size-7 mb-0 mx-auto text-center'>Pricing</h3>
          <button
            type='button'
            className='circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper'
            data-dismiss='modal'
            onClick={() => {
              setModal();
            }}
          >
            <i className='fas fa-times'></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='login-modal-main bg-white rounded-8 overflow-hidden'>
            <div className='row no-gutters'>
              <div className='col-xxl-12 col-xl-12 pt-16'>
                <div className='row justify-content-center mb-7'>
                  <div
                    className='col-lg-4 col-md-6 col-xs-9'
                    data-aos='fade-right'
                    data-aos-duration='1000'
                    data-aos-once='true'
                  >
                    <div className='card border-mercury rounded-8 mb-lg-3 mb-9 px-xl-8 px-lg-8 px-8 pb-8 hover-shadow-hitgray'>
                      <div
                        style={{ height: '330px' }}
                        className='card-header bg-transparent border-hit-gray-opacity-5 text-center pt-11 pb-4'
                      >
                        <div className='pricing-title text-center'>
                          <h5 className='font-weight-semibold font-size-6 text-black-2'>
                            Basic Plan
                          </h5>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              Users:
                            </strong>{' '}
                            1
                          </p>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              Files Supported:
                            </strong>{' '}
                            Excel, HTML, XML, Docx, Digital PDF, Scanned PDF
                          </p>

                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              Chat History in PDF format:
                            </strong>{' '}
                            Yes
                          </p>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              Summarise:
                            </strong>{' '}
                            Yes
                          </p>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              Prompt Library:
                            </strong>{' '}
                            Yes
                          </p>
                        </div>
                        <h2 className='mt-7 text-dodger'>
                          $10
                          <span className='font-size-4 text-smoke font-weight-normal'>
                            /month
                          </span>{' '}
                        </h2>
                      </div>
                      <div className='card-footer bg-transparent border-0 px-0 py-0'>
                        <a
                          href='/#contactus'
                          onClick={(e) => {
                            setselectedPrice('Basic Plan');
                            setModal(false);
                          }}
                          className='btn btn-green btn-h-60 text-white rounded-5 btn-block text-uppercase'
                        >
                          Start with Basic
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-xs-9'>
                    <div className='card border-mercury recomended-pricing rounded-8 mb-lg-3 mb-9 px-xl-8 px-lg-8 px-8 pb-8 mt-lg-n13 hover-shadow-hitgray'>
                      <div
                        style={{ height: '380px' }}
                        className='card-header bg-transparent border-hit-gray-opacity-5 text-center pt-10 pb-5'
                      >
                        <div className='pricing-title text-center'>
                          <span className='font-size-3 font-weight-bold text-uppercase text-dodger mb-9 d-inline-block'>
                            RECOMMENDED
                          </span>
                          <h5 className='font-weight-semibold font-size-6 text-black-2'>
                            Business Plan
                          </h5>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              Users:
                            </strong>{' '}
                            Upto 10
                          </p>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              All included in Basic Plan
                            </strong>{' '}
                          </p>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              Voice Commands:
                            </strong>{' '}
                            Yes
                          </p>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              Response in Audio format:
                            </strong>{' '}
                            Yes
                          </p>
                        </div>
                        <h2 className='mt-7 text-dodger'>
                          $79
                          <span className='font-size-4 text-smoke font-weight-normal'>
                            /month
                          </span>{' '}
                        </h2>
                      </div>
                      <div className='card-footer bg-transparent border-0 px-0 py-0'>
                        <a
                          href='/#contactus'
                          onClick={(e) => {
                            setselectedPrice('Business Plan');

                            setModal(false);
                          }}
                          className='btn btn-green btn-h-60 text-white rounded-5 btn-block text-uppercase'
                        >
                          Start with Business
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    className='col-lg-4 col-md-6 col-xs-9'
                    data-aos='fade-left'
                    data-aos-duration='1000'
                    data-aos-once='true'
                  >
                    <div className='card border-mercury rounded-8 mb-3 px-xl-8 px-lg-8 px-8 pb-8 hover-shadow-hitgray'>
                      <div
                        style={{ height: '330px' }}
                        className='card-header bg-transparent border-hit-gray-opacity-5 text-center pt-11 pb-5'
                      >
                        <div className='pricing-title text-center'>
                          <h5 className='font-weight-semibold font-size-6 text-black-2'>
                            Premium Plan
                          </h5>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              For bigger companies
                            </strong>{' '}
                          </p>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              All included in Business Plan
                            </strong>{' '}
                          </p>
                          <p className='mb-0 pb-0 font-size-3'>
                            {' '}
                            <strong style={{ fontWeight: '500' }}>
                              Writing:
                            </strong>{' '}
                            Yes
                          </p>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              PrivateGPT:
                            </strong>{' '}
                            Yes
                          </p>
                          <p className='mb-0 pb-0 font-size-3'>
                            <strong style={{ fontWeight: '500' }}>
                              Custom Features:
                            </strong>{' '}
                            Yes
                          </p>
                        </div>
                        <h4 className='mt-7 text-dodger'>Contact Sales</h4>
                        <br />
                      </div>
                      <div className='card-footer bg-transparent border-0 px-0 py-0'>
                        <a
                          href='/#contactus'
                          onClick={(e) => {
                            setselectedPrice('Premium Plan');
                            setModal(false);
                          }}
                          className='btn btn-green btn-h-60 text-white rounded-5 btn-block text-uppercase'
                        >
                          Start with Premium{' '}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Login;
