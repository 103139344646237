import { message as msg } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BACKEND_URL } from "../../Action/types";

const Footer = () => {
  const [email, setemail] = useState("");
  const sendMail = async () => {
    if (email !== "") {
      const config = {
        "Content-Type": "application/json",
      };
      const body = { email, query_type: "newsletter" };
      await axios({
        method: "POST",
        url: BACKEND_URL + "customer/query",
        data: body,
        headers: config,
      })
        .then((response) => {
          if (response.data.success) {
            msg.success("Query submitted. Will get back to you soon.");
            setemail("");
          } else {
            msg.success("Something went wrong! Please try again.");
          }
        })
        .catch((error) => {
          msg.success("Something went wrong! Please try again.");
        });
    } else {
      msg.error("Please add email");
    }
  };
  return (
    <div>
      <footer className="footer bg-primary">
        <div className="container  pt-12 pt-lg-19 pb-10 pb-lg-19">
          <div className="row">
            <div className="col-lg-4 col-sm-6 mb-lg-0 mb-9">
              <img
                src="/assets/image/logoWhite.png"
                alt=""
                style={{ width: "90%" }}
                className="footer-logo mb-12"
              />
              <div className="media mb-11">
                <img
                  src="/assets/image/message.png"
                  className="align-self-center mr-3"
                  alt=""
                />
                <div className="media-body pl-5">
                  <a
                    className="mb-0 font-size-4 mb-2 text-white"
                    href="mailto:client.success@sigmatext.ai"
                  >
                    client.success@sigmatext.ai
                  </a>
                  <a
                    className="mb-0 font-size-4  text-white"
                    href="tel:+917972869198"
                  >
                    +91(INDIA)-7972869198
                  </a>
                </div>
              </div>
              <div className="social-icons"></div>
            </div>
            <div className="col-lg-8 col-md-6">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-3 col-xs-6">
                  <div className="footer-widget widget2 mb-md-0 mb-13">
                    <p className="widget-title font-size-4  mb-md-8 mb-7 text-white font-weight-semibold">
                      Company
                    </p>
                    <ul className="widget-links pl-0 list-unstyled ">
                      <li className="mb-6">
                        <Link
                          className="font-size-4  text-white "
                          to="/about-us"
                          alt=""
                          rel="noreferrer"
                        >
                          About us
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          className="font-size-4  text-white "
                          to="/contact-us"
                          alt=""
                          rel="noreferrer"
                        >
                          Contact us
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          className="font-size-4  text-white "
                          to="/privacy-policy"
                          alt=""
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="mb-6">
                        <Link
                          className="font-size-4  text-white "
                          to="/faqs"
                          alt=""
                          rel="noreferrer"
                        >
                          FAQs
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-3 col-xs-6">
                  <div className="footer-widget widget4 mb-sm-0 mb-13">
                    <p className="widget-title font-size-4 mb-md-8 mb-7 text-white font-weight-semibold">
                      Other links
                    </p>
                    <ul className="widget-links pl-0 list-unstyled ">
                      <li className="mb-6">
                        <a
                          className="font-size-4  text-white"
                          href="https://www.momenttext.com/solutions"
                          target="_blank"
                          alt=""
                          rel="noreferrer"
                        >
                          More Services
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="font-size-4  text-white"
                          href="https://www.momenttext.com/blog"
                          target="_blank"
                          alt=""
                          rel="noreferrer"
                        >
                          Blogs
                        </a>
                      </li>
                      <li className="mb-6">
                        <a
                          className="font-size-4  text-white"
                          href="https://www.momenttext.com/case-studies"
                          target="_blank"
                          alt=""
                          rel="noreferrer"
                        >
                          Case Studies
                        </a>
                      </li>
                      <li className="mb-6">
                        <Link
                          className="font-size-4  text-white"
                          to="/text-analyse"
                          alt=""
                          rel="noreferrer"
                        >
                          Use Case
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-3 col-xs-6">
                  <p className="widget-title font-size-4  mb-md-8 mb-7 text-white font-weight-semibold">
                    Subscribe to newsletter
                  </p>
                  <div className="footer-widget widget4">
                    <form className="search-form search-2-adjustment ml-lg-0 ml-md-15">
                      <input
                        className="form-control focus-reset "
                        type="email"
                        id="keyword"
                        placeholder="email@email.com"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                      <button
                        className="btn btn-dark line-height-reset h-200 mt-4 btn-submit w-100 text-uppercase"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          sendMail();
                        }}
                      >
                        Subscribe
                      </button>
                    </form>
                    <ul className="pl-0 list-unstyled d-flex align-items-end ">
                      <li className="d-flex flex-column justify-content-center px-3 mr-3 text-white font-size-4 ">
                        Follow us on:
                      </li>
                      <li className="d-flex flex-column justify-content-center px-3 mr-3">
                        <a
                          href="https://www.linkedin.com/company/momenttext"
                          className=" heading-default-color text-white"
                          target="_blank"
                          alt=""
                          rel="noreferrer"
                        >
                          <i className="fab fa-linkedin-in font-size-3 pt-2"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
