import { message as msg, Table } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { BACKEND_URL } from '../../../Action/types';

const LlmModel = () => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Aggregated Score',
      dataIndex: 'aggregated',
      key: 'aggregated',
    },
    {
      title: 'Privacy',
      dataIndex: 'privacy',
      key: 'privacy',
    },
    {
      title: 'Score by LMSYS.org',
      dataIndex: 'lmsys',
      key: 'lmsys',
    },
    {
      title: 'Context Size',
      dataIndex: 'context',
      key: 'context',
    },
    {
      title: 'Pricing/Cost',
      dataIndex: 'pricing',
      key: 'pricing',
    },
  ];
  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [subject, setsubject] = useState('');
  const [message, setmessage] = useState('');

  const sendMail = async () => {
    if (email !== '') {
      const config = {
        'Content-Type': 'application/json',
      };
      const body = { name, email, subject, message, query_type: 'llm' };
      await axios({
        method: 'POST',
        url: BACKEND_URL + 'customer/query',
        data: body,
        headers: config,
      })
        .then((response) => {
          if (response.data.success) {
            msg.success('Query submitted. Will get back to you soon.');
            setname('');
            setemail('');
            setmessage('');
            setsubject('');
          } else {
            msg.success('Something went wrong! Please try again.');
          }
        })
        .catch((error) => {
          msg.success('Something went wrong! Please try again.');
        });
    } else {
      msg.error('Please add email');
    }
  };

  return (
    <div>
      <section className='pt-15 pt-lg-30 pb-7 pb-lg-10 bg-default'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div
              className='col-xxl-8 col-lg-9 col-md-10 aos-init aos-animate'
              data-aos='fade-in'
              data-aos-duration='1000'
              data-aos-once='true'
            >
              <div className='section-title text-center mb-10 mb-lg-12 mb-lg-10 pb-lg-9 pb-0 '>
                <h1 className='font-size-8 mb-6' style={{ lineHeight: '3rem' }}>
                  Comparative and up-to-date information on the selection of
                  Large Language Models for Artificial Intelligence projects.
                </h1>
                <p className='text-default-color font-size-6 px-5 px-md-8 px-lg-8 px-xl-18 px-xxl-16'>
                  We will be happy to hear and include news, suggestions and
                  ideas, so don't hesitate to contact us if you have any of
                  them.
                </p>
              </div>
            </div>
          </div>
          <div className='row pb-lg-10 justify-content-center'>
            <div
              className='col-xl-12 col-lg-12 col-md-12'
              data-aos='fade-left'
              data-aos-duration='800'
              data-aos-once='true'
            >
              <div className='pl-lg-10 pl-0 d-flex flex-column justify-content-center h-100 pt-lg-0 pt-15 pr-md-10 pr-xl-10 pr-xxl-10 pr-0 '>
                <Table
                  columns={columns}
                  bordered
                  dataSource={[
                    {
                      key: 1,
                      name: (
                        <span>
                          <strong>GPT4 8k</strong>
                          <br /> OpenAI
                        </span>
                      ),
                      aggregated: '92.67%',
                      privacy: (
                        <span>
                          <strong>Low</strong>
                          <br />
                          Data not used to train. Can be revised to enforce
                          policies and laws
                        </span>
                      ),
                      lmsys: 1225,
                      context: '8K tokens',
                      pricing: (
                        <ul>
                          <li>Prompt: $0.03/1k tokens</li>
                          <li>Completion: $0.06/1k tokens</li>
                        </ul>
                      ),
                    },
                    {
                      key: 2,
                      name: (
                        <span>
                          <strong>GPT4 32k</strong>
                          <br /> OpenAI
                        </span>
                      ),
                      aggregated: '92.67%',
                      privacy: (
                        <span>
                          <strong>Low</strong>
                          <br />
                          Data not used to train. Can be revised to enforce
                          policies and laws
                        </span>
                      ),
                      lmsys: 1225,
                      context: '32K tokens',
                      pricing: (
                        <ul>
                          <li>Prompt: $0.06/1k tokens</li>
                          <li>Completion: $0.12/1k tokens</li>
                        </ul>
                      ),
                    },
                    {
                      key: 3,
                      name: (
                        <span>
                          <strong>GPT3.5-turbo (ChatGPT)</strong>
                          <br /> OpenAI
                        </span>
                      ),
                      aggregated: '80.23%',
                      privacy: (
                        <span>
                          <strong>Low</strong>
                          <br />
                          Data not used to train. Can be revised to enforce
                          policies and laws
                        </span>
                      ),
                      lmsys: 1143,
                      context: '4K tokens',
                      pricing: (
                        <ul>
                          <li>$0.002/1k tokens</li>
                        </ul>
                      ),
                    },
                    {
                      key: 4,
                      name: (
                        <span>
                          <strong>PaLM-2</strong>
                          <br /> Google
                        </span>
                      ),
                      aggregated: '78.10%%',
                      privacy: (
                        <span>
                          <strong>Low</strong>
                          <br />
                          Enterprise-grade privacy (Vertex AI). Can be revised
                          to enforce policies and laws
                        </span>
                      ),
                      lmsys: 1042,
                      context: '8K tokens',
                      pricing: (
                        <ul>
                          <li>
                            More information{' '}
                            <a
                              href='https://cloud.google.com/vertex-ai/pricing#generative_ai_models'
                              target='_blank'
                              rel='noreferrer'
                            >
                              here
                            </a>
                          </li>
                        </ul>
                      ),
                    },
                    {
                      key: 5,
                      name: (
                        <span>
                          <strong>Falcon-40b-instruct</strong>
                          <br /> TII
                        </span>
                      ),
                      aggregated: '66.70%',
                      privacy: (
                        <span>
                          <strong>High</strong>
                          <br />
                          Open-source
                        </span>
                      ),
                      lmsys: 'N/A',
                      context: '2k tokens',
                      pricing: (
                        <ul>
                          <li>$6200/month</li>
                        </ul>
                      ),
                    },
                    {
                      key: 6,
                      name: (
                        <span>
                          <strong>LLaMa-13b</strong>
                          <br /> Meta
                        </span>
                      ),
                      aggregated: '53.70%',
                      privacy: (
                        <span>
                          <strong>High</strong>
                          <br />
                          Open-source
                        </span>
                      ),
                      lmsys: 1054,
                      context: '2K tokens',
                      pricing: (
                        <ul>
                          <li>$1374/month</li>
                        </ul>
                      ),
                    },
                    {
                      key: 7,
                      name: (
                        <span>
                          <strong>Vicuna-13b</strong>
                          <br /> LMSYS
                        </span>
                      ),
                      aggregated: '53.70%',
                      privacy: (
                        <span>
                          <strong>High</strong>
                          <br />
                          Open-source
                        </span>
                      ),
                      lmsys: 1054,
                      context: '2K tokens',
                      pricing: (
                        <ul>
                          <li>$1374/month</li>
                        </ul>
                      ),
                    },
                    {
                      key: 8,
                      name: (
                        <span>
                          <strong>MPT-7b-chat</strong>
                          <br /> MosaicML
                        </span>
                      ),
                      aggregated: '51.13%',
                      privacy: (
                        <span>
                          <strong>High</strong>
                          <br />
                          Open-source
                        </span>
                      ),
                      lmsys: 952,
                      context: '2K tokens',
                      pricing: (
                        <ul>
                          <li>$2754/month</li>
                        </ul>
                      ),
                    },
                    {
                      key: 9,
                      name: (
                        <span>
                          <strong>Claude v1</strong>
                          <br /> Anthropic
                        </span>
                      ),
                      aggregated: 'N/A',
                      privacy: (
                        <span>
                          <strong>Low</strong>
                          <br />
                          Data not used to train unless feedback was given by
                          the user. Can be revised to enforce policies and laws
                        </span>
                      ),
                      lmsys: 1195,
                      context: '100K tokens',
                      pricing: (
                        <ul>
                          <li>Prompt: $0.01102/1k tokens</li>
                          <li>Completion: $0.03268/1k tokens</li>
                        </ul>
                      ),
                    },
                    {
                      key: 19,
                      name: (
                        <span>
                          <strong>Claude Instant</strong>
                          <br /> Anthropic
                        </span>
                      ),
                      aggregated: 'N/A',
                      privacy: (
                        <span>
                          <strong>Low</strong>
                          <br />
                          Data not used to train unless feedback was given by
                          the user. Can be revised to enforce policies and laws
                        </span>
                      ),
                      lmsys: 1153,
                      context: '100K tokens',
                      pricing: (
                        <ul>
                          <li>Prompt: $0.00163/1k tokens</li>
                          <li>Completion: $0.00551/1k tokens</li>
                        </ul>
                      ),
                    },
                  ]}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pt-2 pt-lg-0 pb-lg-20 bg-default'>
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div
              className='col-xl-10 col-md-11'
              data-aos='fade-left'
              data-aos-duration='800'
              data-aos-once='true'
            >
              <div className='faq-content pt-lg-4 pt-6'>
                <div
                  className='accordion rounded-10 border-green border-top-5 pl-1'
                  id='accordionExample'
                >
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-1'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-1'
                        aria-expanded='true'
                        aria-controls='collapse2-1'
                      >
                        Criteria used in our LLM Comparison
                      </button>
                    </div>
                    <div
                      id='collapse2-1'
                      className='collapse pr-7 show'
                      aria-labelledby='heading2-1'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        We have combined a number of elements that we believe
                        give a fairly comprehensive and overall useful overview.
                        We compare the model’s features with some respected
                        third-party scores and benchmarks. <br />
                        <ul>
                          <li>
                            <strong>Privacy:</strong>
                            Privacy is mostly enhanced if you own your model and
                            infrastructure, having full control over your data.
                            Overall, well-known LLMs such as GPT are less
                            private than open-source ones, because with
                            open-source models you are the one that decides
                            where is going to be hosted and have full control
                            over it.
                          </li>
                          <li>
                            <strong>User Feedback Score:</strong>
                            Based on the LMSYS leaderboard. LMSYS is an
                            organization that aims to provide LLMs that are open
                            source and available to everyone, being also the
                            creators of Vicuna.
                          </li>
                          <li>
                            <strong>Context size:</strong>
                            The context size refers to how many tokens the LLM
                            can handle. Tokens are the basic units of text or
                            code that an LLM uses to process and generate
                            language. Tokens can be characters, words, subwords,
                            or other segments of text or code, depending on the
                            chosen tokenization method or scheme.
                          </li>
                          <li>
                            <strong>Cost:</strong>
                            We’ve gathered information from well-known services
                            (like OpenAI or Anthropic) and, for the open-source
                            models, we’ve utilized different hardware based on
                            the requirements recommended by those models, apart
                            from the ones we have tested ourselves.
                          </li>
                          <li>
                            <strong>Aggregated score:</strong>
                            An average score between three state-of-the-art
                            benchmarks: MMLU (Massive Multi-task Language
                            Understanding), HellaSwag (Commonsense tasks), and
                            ARC (Reasoning).
                          </li>{' '}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-2'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-2'
                        aria-expanded='false'
                        aria-controls='collapse2-2'
                      >
                        Selecting the right LLM
                      </button>
                    </div>
                    <div
                      id='collapse2-2'
                      className='collapse pr-7'
                      aria-labelledby='heading2-2'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        Selecting the right Language Learning Model is crucial
                        because the effectiveness of your AI solution largely
                        depends on it. Each model has its strengths and
                        weaknesses, and some models may be more suitable for
                        certain tasks than others. Additionally, models vary in
                        terms of resource requirements, such as the amount of
                        training data needed and processing power. The correct
                        model choice can also have implications for your
                        project's privacy and cost. Here are a few factors to
                        consider when choosing a Language Learning Model:
                        <br />
                        <ul>
                          <li>
                            <strong>Accuracy:</strong> How well can the model
                            perform the task you need? Can it generate accurate
                            and coherent responses? Can it understand and
                            generate text in the language or languages you
                            require?
                          </li>
                          <li>
                            <strong>Efficiency:</strong> How resource-intensive
                            is the model? Does it require a lot of processing
                            power? How long does it take to generate responses?
                          </li>
                          <li>
                            <strong>Privacy:</strong> Does the model ensure data
                            privacy? Can it handle sensitive data securely? A
                            solid Service Level Agreement is enough or your use
                            case requires extreme control on data access and
                            running everything on-prem?
                          </li>
                          <li>
                            <strong>Cost:</strong> What is the cost to use or
                            refine the model? Are there associated costs with
                            using the necessary infrastructure to run the model?
                            What’s the cost of associated databases for
                            long-term storage in your use case?
                          </li>
                          <li>
                            <strong>Flexibility:</strong> Can the model adapt to
                            different tasks or contexts? Is it easy to customize
                            or adapt to your specific needs?
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-3'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-3'
                        aria-expanded='false'
                        aria-controls='collapse2-3'
                      >
                        LLM available options
                      </button>
                    </div>
                    <div
                      id='collapse2-3'
                      className='collapse pr-7'
                      aria-labelledby='heading2-3'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        The market is incredibly active, with new developments
                        happening almost daily, but today we have curated ten
                        models that are powerful and well-established. These
                        models have been chosen because of their popularity in
                        both commercial (OpenAi, Google…) and open-source
                        solutions (like falcon, currently top 1 in Hugging
                        Face’s leaderboard). ‍<br />
                        ‍<br />
                        ‍If you think there are others that should be included,
                        feel free to drop us a line.
                        <br />
                        <ul>
                          <li>
                            <strong>GPT4 8k/32k:</strong> This is the model used
                            for paying customers of the chatGPT premium plan or
                            those using OpenAI APIs. The current versions
                            include both 8k and 32K context size.
                          </li>
                          <li>
                            <strong>GPT3.5-turbo (ChatGPT):</strong> This is the
                            model that is used on ChatGPT’s free plan, as of
                            June 2023.
                          </li>
                          <li>
                            <strong>PaLM-2:</strong> The latest model introduced
                            by Google, which powers PaLM APIs and Google Bard.
                          </li>
                          <li>
                            <strong>Falcon-40b-instruct:</strong> From the
                            technology innovation institute, trained with 1,000B
                            tokens and currently (June 2023) top 1 in Hugging
                            Face’s leaderboard.
                          </li>
                          <li>
                            <strong>LLaMa-13b/Vicuna-13b:</strong> Both are
                            based on LLaMa, a model provided by Meta (Facebook).
                            In the case of Vicuna, it is trained with 70k
                            conversations from ShareGPT, a platform in which
                            users share their chatGPT conversations.
                          </li>
                          <li>
                            <strong>MPT-7b-chat:</strong> Specialized in
                            dialogue generation and provided by the MosaicML
                            company.
                          </li>
                          <li>
                            <strong>Claude v1/Instant:</strong> A model built by
                            Anthopic. It can be used via APIs or by using their
                            Slack integration.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-4'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-4'
                        aria-expanded='false'
                        aria-controls='collapse2-4'
                      >
                        Conclusions
                      </button>
                    </div>
                    <div
                      id='collapse2-4'
                      className='collapse pr-7'
                      aria-labelledby='heading2-4'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        The proprietary options from OpenAI, Anthropic, and
                        Google are currently the most comprehensive and
                        powerful. As for open-source models, the best option
                        available is Falcon-40B-instruct, with a reasonable cost
                        given its benchmark scores, and new models appearing
                        every week. Open-source models are very appealing
                        because you can host your own LLM instance on a server
                        under your control, offering different possibilities in
                        terms of security, privacty and cost flexibility.{' '}
                        <strong>
                          Our approach at PrivateGPT is a combination of models.
                        </strong>{' '}
                        We're about creating hybrid systems that can combine and
                        optimize the use of different models based on the needs
                        of each part of the project.With the right configuration
                        and design, you can combine different LLMs to offer a
                        great experience while meeting other requirements in
                        terms of security and privacy.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pt-7 pt-lg-18 bg-default pb-2 pb-lg-24'>
        <div className='container'>
          <div className='row pb-lg-6 justify-content-center'>
            <div className='col-xxl-8 col-xl-9 col-lg-10 '>
              <h2 className='font-size-9 text-center mb-5 text-primary'>
                Contact Us
              </h2>
              <p className='text-default-color font-size-6 px-5 px-md-10 px-lg-15 px-xl-24 px-xxl-22 text-center'>
                <strong>ChooseLLM is an initiative by PrivateGPT</strong>
              </p>
              <p className='text-default-color font-size-4 px-5 px-md-10 px-lg-15 px-xl-24 pb-10 px-xxl-22 text-center'>
                If this sounds interesting for your organisation. Submit your
                application and let us know about your needs and ideas, and
                we'll get in touch if we can help you.
              </p>
              <div className='row pb-lg-6 justify-content-center'>
                <div className='col-xxl-10 col-xl-10 col-lg-10 '>
                  <div className='bg-white px-9 pt-9 pb-7 shadow-8 rounded-4 contactBorder'>
                    <form>
                      <div className='row'>
                        <div className='col-12 mb-7'>
                          <label className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'>
                            Your Name
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Jhon Doe'
                            value={name}
                            onChange={(e) => setname(e.target.value)}
                          />
                        </div>
                        <div className='col-lg-6 mb-7'>
                          <label className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'>
                            E-mail
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='example@gmail.com'
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                        <div className='col-lg-6 mb-7'>
                          <label className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'>
                            Subject
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Special contract'
                            value={subject}
                            onChange={(e) => setsubject(e.target.value)}
                          />
                        </div>
                        <div className='col-lg-12 mb-7'>
                          <label className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'>
                            Message
                          </label>
                          <textarea
                            name=''
                            id=''
                            placeholder='Type your message'
                            className='form-control h-px-144'
                            value={message}
                            onChange={(e) => setmessage(e.target.value)}
                          ></textarea>
                        </div>
                        <div className='col-lg-12 pt-4'>
                          <button
                            type='button'
                            className='btn btn-primary text-uppercase w-100 h-px-48'
                            onClick={(e) => {
                              e.preventDefault();
                              sendMail();
                            }}
                          >
                            Send Now
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className='mt-8'>
                      <h3 className='font-size-4'>Contact Information</h3>
                      <div className='media mb-2'>
                        <div className='mr-6'>
                          <i className='fas fa-phone-alt mt-2'></i>
                        </div>
                        <p className='font-size-4 mb-0'>
                          <a
                            className='mb-0 font-size-4  fontBold400'
                            href='tel:+917972869198'
                          >
                            +91(INDIA)-7972869198
                          </a>
                        </p>
                      </div>
                      <div className='media mb-2'>
                        <div className='mr-6'>
                          <i className='fas fa-envelope mt-2'></i>
                        </div>
                        <p className='font-size-4 mb-0'>
                          <a
                            className='mb-0 font-size-4 mb-2 fontBold400'
                            href='mailto:client.success@sigmatext.ai'
                          >
                            client.success@sigmatext.ai
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LlmModel;
