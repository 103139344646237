import { Card, Input, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { chatFile, listSearchResults } from "../../../Api/Upload";
import axios from "axios";
import { BACKEND_URL } from "../../../Action/types";

const Index = ({
  selectePrompts,
  setselectePrompts,
  selectePromptsDesc,
  setselectePromptsDesc,
}) => {
  const [queryText, setqueryText] = useState("");
  const heightRef = useRef(null);
  const [conversationHistory, setconversationHistory] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("promptChats") !== null) {
      setconversationHistory(JSON.parse(sessionStorage.getItem("promptChats")));
    } else {
      sessionStorage.setItem("promptChats", JSON.stringify([]));
    }
  }, [loading]);

  const chatText = async (prompt, text, chat) => {
    if (prompt === "") {
      message.error("Please select analyse type!");
      return;
    }
    if (text === "") {
      message.error("Please enter your query!");
      return;
    }
    setloading(true);
    setqueryText("");
    const config = {
      "Content-Type": "application/json",
    };
    let body = { text, prompt };
    await axios({
      method: "POST",
      url: BACKEND_URL + "chat/conversation",
      data: body,
      headers: config,
    })
      .then((response) => {
        setloading(false);
        if (response.data.success) {
          chat.push({
            type: "incoming",
            text: response.data.conversation.bot_response.text,
            date: moment(
              response.data.conversation.bot_response.created_at
            ).format("DD-MM-YYYY hh:mm"),
          });
          setqueryText("");
          setconversationHistory(chat);
          sessionStorage.setItem("promptChats", JSON.stringify(chat));
        } else {
        }
      })
      .catch((error) => {
        setloading(false);
      });
  };

  useEffect(() => {
    if (heightRef.current !== null) {
      var objDiv = heightRef.current;
      objDiv.scrollTop = objDiv.scrollHeight + 1000;
    }
  }, [conversationHistory]);

  return (
    <div className="dashboard-main-container mt-9 mt-lg-9" id="dashboard-body">
      <div className="container position-relative">
        <div className="row ">
          <div
            className="col-11 mx-auto heightFull"
            style={{ marginTop: "-15px" }}
          >
            <div
              className="col-12 mx-auto px-14 "
              style={{ marginTop: "89.5vh", zIndex: 9, position: "relative" }}
            >
              <Input
                placeholder="Enter your query here..."
                style={{ fontSize: "15px", height: "45px" }}
                value={queryText}
                suffix={
                  <SendOutlined
                    className="text-primary"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (selectePrompts === "") {
                        message.error("Please select analyse type!");
                        return;
                      }
                      if (queryText === "") {
                        message.error("Please enter your query!");
                        return;
                      }
                      if (queryText !== "" && selectePrompts !== "") {
                        let chat = [...conversationHistory];
                        chat.push({
                          type: "outgoing",
                          text: queryText,
                          date: moment(new Date()).format("DD-MM-YYYY hh:mm"),
                        });
                        setconversationHistory(chat);
                        sessionStorage.setItem(
                          "promptChats",
                          JSON.stringify(chat)
                        );
                        setloading(true);
                        chatText(selectePromptsDesc, queryText, chat);
                      }
                    }}
                  />
                }
                onChange={(e) => {
                  setqueryText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    if (selectePrompts === "") {
                      message.error("Please select analyse type!");
                      return;
                    }
                    if (queryText === "") {
                      message.error("Please enter your query!");
                      return;
                    }
                    if (queryText !== "" && selectePrompts !== "") {
                      let chat = [...conversationHistory];
                      chat.push({
                        type: "outgoing",
                        text: queryText,
                        date: moment(new Date()).format("DD-MM-YYYY hh:mm"),
                      });
                      setconversationHistory(chat);
                      sessionStorage.setItem(
                        "promptChats",
                        JSON.stringify(chat)
                      );
                      setloading(true);
                      chatText(selectePromptsDesc, queryText, chat);
                    }
                    if (queryText !== "" && queryText !== "") {
                      let chat = [...conversationHistory];
                      chat.push({
                        type: "outgoing",
                        text: queryText,
                        date: moment(new Date()).format("DD-MM-YYYY hh:mm"),
                      });
                      setconversationHistory(chat);
                      sessionStorage.setItem(
                        "promptChats",
                        JSON.stringify(chat)
                      );
                      setloading(true);
                      chatText(selectePromptsDesc, queryText, chat);
                    }
                  }
                }}
              />
            </div>
            <Card bodyStyle={{ paddingBottom: "4px" }}>
              <div className="chat-history" ref={heightRef}>
                <ul className="m-b-0">
                  {conversationHistory.map((ele, index) =>
                    ele.type === "outgoing" ? (
                      <li className="clearfix" key={index}>
                        <div className="message-data text-right">
                          <span className="message-data-time">{ele.date}</span>
                          <img
                            src="https://bootdey.com/img/Content/avatar/avatar7.png"
                            alt="avatar"
                          />
                        </div>
                        <div className="message other-message float-right text-left">
                          {ele.text}
                        </div>
                      </li>
                    ) : (
                      <li className="clearfix" key={index}>
                        <div className="message-data">
                          <span className="message-data-time">{ele.date}</span>
                        </div>
                        <div className="message my-message">{ele.text}</div>
                      </li>
                    )
                  )}
                  {loading && (
                    <li className="clearfix">
                      <div className="message-data">
                        <span className="message-data-time"></span>
                      </div>
                      <div
                        className="message my-message"
                        style={{ height: "30px" }}
                      >
                        <img
                          src="./assets/image/typing.gif"
                          style={{ height: "35px", marginTop: "-28px" }}
                          alt=""
                        />
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </Card>
          </div>
        </div>
        <div className="row"></div>
      </div>
    </div>
  );
};

Index.propTypes = {
  isError: PropTypes.any,
  isErrorType: PropTypes.any,
  errMessage: PropTypes.any,
  isAuthenticated: PropTypes.any,
  data: PropTypes.any.isRequired,
  typing: PropTypes.any,
  conversationData: PropTypes.any,
  listSearchResults: PropTypes.any,
  SearchData: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isError: state.upload.isErrorChat,
  isErrorType: state.upload.isErrorTypeChat,
  errMessage: state.upload.errMessageChat,
  data: state.upload.data,
  typing: state.upload.typing,
  isAuthenticated: state.auth.isAuthenticated,
  conversationData: state.upload.conversationData,
  SearchData: state.upload.SearchData,
});
export default connect(mapStateToProps, { chatFile, listSearchResults })(Index);
