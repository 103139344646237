import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  LogoutOutlined,
  CloseOutlined,
  LoginOutlined,
  DownOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  RedoOutlined,
  CloudUploadOutlined,
  CloudSyncOutlined,
} from "@ant-design/icons";
import {
  Dropdown,
  Input,
  message,
  Popconfirm,
  Popover,
  Tooltip,
  Upload,
} from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  BACKEND_URL,
  CONVERSATION_DATA,
  SEARCH_DATA,
  SET_FILE_DATA,
  TRIGGER_CONVERSATION,
} from "../../Action/types";
import store from "../../store";
import {
  fileDelete,
  listChatHistory,
  listFolderChats,
  reDoProcessing,
  syncFiles,
  uploadInit,
  uploadInitMulti,
  uploadUrl,
} from "../../Api/Upload";
import axios from "axios";
import { dmsStatus, signout } from "../../Api/Auth";
const { Search } = Input;

let reload = false;
let count = 0;
let interval = null;

const SideBar = ({
  createjob,
  uploadInitMulti,
  uploadUrl,
  setshowModal1,
  setshowModal,
  isAuthenticated,
  data,
  uploadInit,
  isError,
  isErrorType,
  errMessage,
  listChatHistory,
  tirggerApi,
  fileDelete,
  listFolderChats,
  reDoProcessing,
  tourRef2,
  tourRef1,
  openPopover,
  setopenPopover,
  prompts = false,
  selectePrompts,
  selectePromptsDesc,
  setselectePrompts,
  setselectePromptsDesc,
  setOpen,
  currentDirPath,
  setcurrentDirPath,
  nextCloud,
  dmsStatus,
  syncFiles,
  signout,
}) => {
  const [fileData, setfileData] = useState([]);
  const [loader, setloader] = useState(true);
  useEffect(() => {
    reload = true;
  }, [isAuthenticated]);

  const history = useHistory();

  useEffect(() => {
    if (
      localStorage.getItem("isTourDone") === null &&
      history.location.pathname === "/dashboard"
    ) {
      setOpen(true);
    }
  }, [history, setOpen]);

  useEffect(() => {
    reload = true;
  }, []);

  useEffect(() => {
    if (reload && data.length > 0) {
      setmainSelected(true);
      listChatHistory("", false, true, true, data);
      reload = false;
    }
  }, [listChatHistory, data]);

  const [messageApi, contextHolder] = message.useMessage();
  const key = "updatable";
  const openMessage = () => {
    messageApi.open({
      key,
      type: "loading",
      content: "Uploading your file...",
      duration: 0,
    });
  };

  const closeMessage = useRef(null);
  closeMessage.current = () => {
    messageApi.open({
      key,
      type: "loading",
      content: "Uploading your file...",
      duration: 1,
    });
    setfileData([]);
    setloader(true);
  };

  const getProgress = (
    index,
    level,
    item,
    path,
    main = false,
    listed = false
  ) => {
    clearTimeout(interval);
    axios({
      method: "GET",
      url: BACKEND_URL + "aws/s3?file_id=" + item.file_id,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.data.success) {
          if (
            response.data.file_data.is_file_processed &&
            response.data.file_data.is_summary_generated
          ) {
            async function recursive(level, arr) {
              if (level.length === 0) {
                let newSet = arr.map((ele) => {
                  if (ele.file_id === item.file_id) {
                    return { ...item, isProcessed: true, isSummary: true };
                  } else {
                    return ele;
                  }
                });
                return newSet;
              } else if (level.length === 1) {
                let newSet = arr[level[0]].folderData.map((ele) => {
                  if (ele.file_id === item.file_id) {
                    return { ...item, isProcessed: true, isSummary: true };
                  } else {
                    return ele;
                  }
                });
                arr[level[0]].folderData = newSet;
                return arr;
              } else {
                let ind = level.shift();
                arr[ind].folderData = await recursive(
                  level,
                  arr[ind].folderData
                );
                return arr;
              }
            }
            let rec = await recursive(level, [...data]);
            sessionStorage.setItem("history", JSON.stringify(rec));
            store.dispatch({ type: SET_FILE_DATA, payload: rec });
            listChatHistory(item.file_id, true, true, true, item);
          } else if (response.data.file_data.is_file_processed && !listed) {
            async function recursive(level, arr) {
              if (level.length === 0) {
                let newSet = arr.map((ele) => {
                  if (ele.file_id === item.file_id) {
                    return { ...item, isProcessed: true };
                  } else {
                    return ele;
                  }
                });
                return newSet;
              } else if (level.length === 1) {
                let newSet = arr[level[0]].folderData.map((ele) => {
                  if (ele.file_id === item.file_id) {
                    return { ...item, isProcessed: true };
                  } else {
                    return ele;
                  }
                });
                arr[level[0]].folderData = newSet;
                return arr;
              } else {
                let ind = level.shift();
                arr[ind].folderData = await recursive(
                  level,
                  arr[ind].folderData
                );
                return arr;
              }
            }
            if (!index) {
              let rec = await recursive(level, [...data]);
              sessionStorage.setItem("history", JSON.stringify(rec));
              store.dispatch({ type: SET_FILE_DATA, payload: rec });
            }
            listChatHistory(item.file_id, true, true, false, item);
            clearTimeout(interval);
            interval = setTimeout(() => {
              getProgress(false, level, item, path, main, true);
            }, 3000);
          } else {
            clearTimeout(interval);
            interval = setTimeout(() => {
              getProgress(false, level, item, path, main, listed);
            }, 3000);
          }
        } else {
        }
      })
      .catch((error) => {});
  };

  const getProgressFolder = (level, item, path, main = false) => {
    clearTimeout(interval);
    let newPath = path.split("/");
    newPath.pop();
    newPath = newPath.join("/");
    axios({
      method: "GET",
      url: BACKEND_URL + "aws/s3?order_by=1&current_file_path=" + newPath,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.data.success) {
          if (
            response.data.file_data.filter((ele) => ele.name === item.file)
              .length > 0
          ) {
            if (
              response.data.file_data.filter((ele) => ele.name === item.file)[0]
                .files_processed_count > 0
            ) {
              async function recursive(level, arr) {
                if (level.length === 1) {
                  arr[level[0]].processedCount =
                    response.data.file_data.filter(
                      (ele) => ele.name === item.file
                    ).length > 0
                      ? response.data.file_data.filter(
                          (ele) => ele.name === item.file
                        )[0].files_processed_count
                      : arr[level[0]].processedCount;
                  return arr;
                } else {
                  let ind = level.shift();
                  arr[ind].folderData = await recursive(
                    level,
                    arr[ind].folderData
                  );
                  return arr;
                }
              }
              let rec = await recursive(level, [...data]);
              sessionStorage.setItem("history", JSON.stringify(rec));
              store.dispatch({ type: SET_FILE_DATA, payload: rec });
              listChatHistory(
                path,
                false,
                item.isProcessed,
                item.isSummary,
                item
              );
              // listChatHistory(item.file_id, true, true, item);
            } else {
              clearTimeout(interval);
              interval = setTimeout(() => {
                getProgressFolder(level, item, path, main);
              }, 3000);
            }
          }
        } else {
        }
      })
      .catch((error) => {});
  };

  const callConversations = (
    level,
    item,
    path,
    main = false,
    onclickVal = false
  ) => {
    store.dispatch({
      type: CONVERSATION_DATA,
      payload: {},
    });
    store.dispatch({ type: SEARCH_DATA, payload: [] });
    clearTimeout(interval);
    setcurrentDirPath(path);
    if (main) {
      listChatHistory("", false, true, true, item);
    } else {
      if (item.is_folder && item.is_folder_open) {
        if (item.processedCount === 0) {
          listFolderChats(path, [...level], [...data]);
          getProgressFolder(level, item, path, main);
        } else {
          listFolderChats(path, [...level], [...data]);
          listChatHistory(path, false, item.isProcessed, item.isSummary, item);
        }
      } else if (!item.is_folder) {
        if (item.isProcessed) {
          if (!item.isSummary) {
            getProgress(true, level, item, path, main);
          } else {
            listChatHistory(
              item.file_id,
              true,
              item.isProcessed,
              item.isSummary,
              item
            );
          }
        } else {
          getProgress(false, level, item, path, main);
        }
      } else {
        if (item.processedCount === 0) {
          getProgressFolder(level, item, path, main);
        } else {
          listChatHistory(path, false, item.isProcessed, item.isSummary, item);
        }
      }
    }
  };

  useEffect(() => {
    if (isError && isErrorType === "SUCCESS") {
      message.success(errMessage);
      setfileData([]);
      setloader(true);
      closeMessage.current();
    } else if (isError && isErrorType === "FAIL") {
      setfileData([]);
      setloader(true);
      closeMessage.current();
      message.error(errMessage);
    } else {
      setfileData([]);
      setloader(true);
    }
  }, [isError, isErrorType, errMessage]);

  const props = {
    width: "280px",
    multiple: false,
    onRemove: (file, fileList) => {
      const newFileList = fileData.filter((ele) => ele.uid !== file.uid);
      setfileData(newFileList);
    },
    beforeUpload: (file, fileList) => {
      setfileData([file]);
      return false;
    },
    onChange: (info) => {
      setloader(false);
      if (currentDirPath === "") {
        setmainSelected(true);
      }
      openMessage();
      uploadInit(info.file, currentDirPath);
      setTimeout(() => {
        setfileData([]);
        setloader(true);
      }, 5);
    },
    fileData,
  };

  const props2 = {
    width: "280px",
    directory: true,
    multiple: false,
    onRemove: (file, fileList) => {
      const newFileList = fileData.filter((ele) => ele.uid !== file.uid);
      setfileData(newFileList);
    },
    beforeUpload: (file, fileList) => {
      // uploadInitMulti(fileList);
      setfileData(fileList);
      return false;
    },
    onChange: ({ file, fileList }) => {
      count++;
      if (count === fileList.length) {
        setloader(false);
        count = 0;
        if (currentDirPath === "") {
          setmainSelected(true);
        }
        uploadInitMulti(fileList, currentDirPath);
        setTimeout(() => {
          setfileData([]);
          setloader(true);
        }, 5);
      }
    },
    fileData,
  };

  useEffect(() => {
    if (!isAuthenticated) {
      if (window.location.pathname.includes("education")) {
        history.push("/education");
      } else {
        history.push("/");
      }
    }
  }, [isAuthenticated, history]);

  const items = [
    {
      label: (
        <Link
          to="/private-gpt"
          className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center py-2 my-0"
        >
          Private GPT
        </Link>
      ),
      key: "1",
    },
    {
      label: (
        <Link
          to="/llm"
          className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center py-2 my-0"
        >
          LLM
        </Link>
      ),
      key: "2",
    },
  ];
  const menuProps = {
    items,
  };
  const [mainSelected, setmainSelected] = useState(true);

  const folderStructure = (ele, index, level = [], path = "") => {
    level.push(index);
    if (ele.is_folder) path = path + "/" + ele.file;
    var newComponent = (
      <li
        className={"noactive  " + (ele.isSelected ? "active" : "")}
        onClick={() => {}}
        key={"o" + index}
      >
        <Link
          to="#!"
          className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
          onClick={async (e) => {
            var dataz = [...data];
            dataz = data.map((item) => {
              return { ...item, isSelected: false };
            });
            dataz = await recersive(dataz, [...level]);
            function recersive(data, point) {
              if (point.length === 1) {
                data[point[0]].is_folder_open = !data[point[0]].is_folder_open;
                data = data.map((elt) => {
                  return { ...elt, isSelected: false };
                });
                data[point[0]].isSelected = true;
                data[point[0]].folderData = data[point[0]].folderData.map(
                  (elt) => {
                    return { ...elt, isSelected: false, is_folder_open: false };
                  }
                );
                callConversations(level, data[point[0]], path, false, false);
                return data;
              } else {
                let curr = point.shift();
                data = data.map((elt) => {
                  return { ...elt, isSelected: false };
                });
                data[curr].is_folder_open = true;
                data[curr].folderData = recersive(data[curr].folderData, point);

                return data;
              }
            }
            sessionStorage.setItem("history", JSON.stringify(dataz));
            setmainSelected(false);
            store.dispatch({ type: SET_FILE_DATA, payload: [] });
            store.dispatch({ type: SET_FILE_DATA, payload: dataz });
            setTimeout(() => {
              store.dispatch({
                type: TRIGGER_CONVERSATION,
                payload: true,
              });
            }, 50);
          }}
        >
          {ele.is_folder_open ? (
            <CaretDownOutlined className="mr-1" style={{ fontSize: "10px" }} />
          ) : (
            <CaretRightOutlined className="mr-1" style={{ fontSize: "10px" }} />
          )}
          {ele.is_folder_open ? (
            <i className="fa fa-folder-open " style={{ width: "22px" }}></i>
          ) : (
            <i className="fa fa-folder " style={{ width: "22px" }}></i>
          )}
          <span style={{ lineHeight: "25px", height: "25px" }}>
            <Tooltip title={ele.file} placement="bottom">
              {ele.file.substring(0, 15)} {ele.file.length > 15 && "..."}
            </Tooltip>
          </span>
        </Link>
        {ele.is_folder_open && ele.folderData.length > 0 && (
          <ul className="ml-2 list-unstyled  ">
            {ele.folderData.length > 0 &&
              ele.folderData.map((itemz, index) =>
                itemz.is_folder ? (
                  folderStructure(itemz, index, [...level], path)
                ) : (
                  <li
                    className={
                      "noactive  " + (itemz.isSelected ? "active" : "")
                    }
                    onClick={async (e) => {
                      var dataz = [...data];
                      dataz = data.map((item) => {
                        return {
                          ...item,
                          isSelected: false,
                          is_folder_open: false,
                        };
                      });
                      dataz = await recersive(dataz, [...level]);
                      function recersive(data, point) {
                        if (point.length === 1) {
                          data[point[0]].isSelected = false;
                          data[point[0]].is_folder_open = true;
                          data[point[0]].folderData = data[
                            point[0]
                          ].folderData.map((elt) => {
                            return {
                              ...elt,
                              isSelected: false,
                              is_folder_open: false,
                            };
                          });
                          data[point[0]].folderData[index].isSelected = true;
                          callConversations(
                            level,
                            data[point[0]].folderData[index],
                            path,
                            false,
                            false
                          );
                          return data;
                        } else {
                          let curr = point.shift();
                          data[curr].is_folder_open = true;
                          data[curr].folderData = recersive(
                            data[curr].folderData,
                            point
                          );
                          data = data.map((elt) => {
                            return { ...elt, isSelected: false };
                          });
                          return data;
                        }
                      }
                      sessionStorage.setItem("history", JSON.stringify(dataz));
                      setmainSelected(false);
                      store.dispatch({ type: SET_FILE_DATA, payload: [] });
                      store.dispatch({ type: SET_FILE_DATA, payload: dataz });
                      setTimeout(() => {
                        store.dispatch({
                          type: TRIGGER_CONVERSATION,
                          payload: true,
                        });
                      }, 50);
                    }}
                    key={"o" + index}
                  >
                    <Link
                      to="#!"
                      className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                      onClick={(e) => {}}
                    >
                      <i
                        className="fa fa-file "
                        style={{ marginLeft: "15px", width: "22px" }}
                      ></i>
                      <span style={{ lineHeight: "25px", height: "25px" }}>
                        <Tooltip title={itemz.file} placement="bottom">
                          {itemz.file.substring(0, 15)}{" "}
                          {itemz.file.length > 15 && "..."}
                        </Tooltip>
                      </span>
                      <Tooltip title="Process file" placement="bottom">
                        <span
                          className="closeButton"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            reDoProcessing(itemz.file_id, true);
                          }}
                          style={{ right: "20px", marginTop: "-3px" }}
                        >
                          <RedoOutlined
                            className=""
                            style={{ fontSize: "10.5px" }}
                          />
                        </span>
                      </Tooltip>
                      {!itemz.is_folder && (
                        <Popconfirm
                          placement="topRight"
                          title={"Delete file"}
                          description={"Are you sure?"}
                          okText="Yes"
                          cancelText="No"
                          onConfirm={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            clearTimeout(interval);
                            fileDelete(itemz.file_id, level, path, [...data]);
                            if (itemz.isSelected) {
                              store.dispatch({
                                type: CONVERSATION_DATA,
                                payload: {},
                              });
                              clearTimeout(interval);
                            }
                          }}
                        >
                          <Tooltip title="Delete file" placement="bottom">
                            <span
                              className="closeButton"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                              }}
                              style={{ marginTop: "-3px" }}
                            >
                              <CloseOutlined
                                className=""
                                style={{ fontSize: "10.5px" }}
                              />
                            </span>
                          </Tooltip>
                        </Popconfirm>
                      )}
                    </Link>
                  </li>
                )
              )}
          </ul>
        )}
      </li>
    );
    return newComponent;
  };

  //prompts data

  return (
    <div>
      {contextHolder}
      <div className="dashboard-sidebar-wrapper pt-5" id="sidebar">
        <div className="brand-logo px-6 text-left">
          <Link to="#!">
            <img
              src="/assets/image/logoBlack.png"
              alt=""
              style={{ width: "100%", height: "40px" }}
            />
          </Link>
        </div>
        {!prompts && (
          <div
            className="mt-6 mb-3 px-6 uploadButn"
            id="#uploadButn"
            style={{ height: "35px", overflow: "auto" }}
          >
            <Popover
              content={
                <div
                  style={{
                    width: "280px",
                    height: "190px",
                    overflow: "hidden",
                  }}
                >
                  {loader && (
                    <Search
                      placeholder="Enter your URL"
                      allowClear
                      enterButton="Proceed"
                      size="large"
                      onSearch={(e) => {
                        openMessage();
                        if (e !== "") {
                          if (currentDirPath === "") {
                            setmainSelected(true);
                          }
                          uploadUrl(e, currentDirPath);
                        }
                      }}
                    />
                  )}
                  <div className="border-bottom position-relative mt-5 mb-5">
                    <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontWeight: "600",
                        background: "#fff",
                      }}
                    >
                      OR
                    </span>
                  </div>
                  {loader && (
                    <Upload {...props}>
                      <button
                        to="create-job"
                        style={{ width: "280px" }}
                        className="btn btn-dark font-size-3 font-weight-normal  text-uppercase mt-2"
                        onClick={() => {}}
                      >
                        Choose a file
                      </button>
                    </Upload>
                  )}
                  <div className="border-bottom position-relative mt-5 mb-5">
                    <span
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        fontWeight: "600",
                        background: "#fff",
                      }}
                    >
                      OR
                    </span>
                  </div>
                  {loader && (
                    <Upload {...props2}>
                      <button
                        to="create-job"
                        style={{ width: "280px" }}
                        className="btn btn-dark font-size-3 font-weight-normal  text-uppercase mt-2"
                        onClick={() => {}}
                      >
                        Choose a folder
                      </button>
                    </Upload>
                  )}
                </div>
              }
              open={openPopover}
              onOpenChange={() => setopenPopover(!openPopover)}
              placement="rightTop"
            >
              <button
                to="create-job"
                ref={tourRef1}
                className="btn btn-primary font-size-2 font-weight-normal w-100 text-uppercase"
                style={{ height: "35px" }}
                onClick={() => {}}
              >
                <span className="mr-2 d-inline-block ">+</span>
                Upload
              </button>
            </Popover>
          </div>
        )}
        <div ref={tourRef2}>
          {!prompts && (
            <ul className="list-unstyled dashboard-layout-sidebar pb-0 mb-1">
              <li
                className={"noactive  " + (mainSelected ? "active" : "")}
                onClick={() => {}}
              >
                <Link
                  to="#!"
                  className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                  onClick={async (e) => {
                    var dataz = [...data];
                    setmainSelected(true);
                    dataz = data.map((item) => {
                      return {
                        ...item,
                        isSelected: false,
                        is_folder_open: false,
                      };
                    });
                    callConversations([], dataz, "", true, false);
                    sessionStorage.setItem("history", JSON.stringify(dataz));
                    store.dispatch({ type: SET_FILE_DATA, payload: [] });
                    store.dispatch({ type: SET_FILE_DATA, payload: dataz });
                    setTimeout(() => {
                      store.dispatch({
                        type: TRIGGER_CONVERSATION,
                        payload: true,
                      });
                    }, 50);
                  }}
                >
                  <i className="fa fa-folder " style={{ width: "22px" }}></i>
                  <span style={{ lineHeight: "25px", height: "25px" }}>
                    My Files
                  </span>
                </Link>
              </li>
            </ul>
          )}
          {prompts ? (
            <>
              <p
                className="mt-7 mb-0 pl-3 text-white py-2 bg-primary"
                style={{ fontWeight: "600", fontSize: "13px" }}
              >
                Select Analyse type
              </p>
              <ul className="list-unstyled ml-0 dashboard-layout-sidebar sidebar-content-height mt-0 border-top pt-3">
                <li
                  className={
                    "noactive  " +
                    (selectePrompts === "Sentiment Analysis" ? "active" : "")
                  }
                  onClick={() => {}}
                >
                  <Link
                    to="#!"
                    className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setselectePrompts("Sentiment Analysis");
                      setselectePromptsDesc(
                        "Please analyze the given text and determine its sentiment as either positive, negative, or neutral. Consider the overall tone and emotion expressed in the text to make your judgment. If the sentiment is predominantly optimistic, cheerful, or affirmative, classify it as positive. If the sentiment is mainly pessimistic, displeased, or critical, classify it as negative. If the text lacks a distinct emotional tone or if it expresses a mix of positive and negative feelings, classify it as neutral."
                      );
                    }}
                  >
                    <i className="fa fa-file " style={{ width: "22px" }}></i>
                    <span style={{ lineHeight: "25px", height: "25px" }}>
                      Sentiment Analysis
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    "noactive  " +
                    (selectePrompts === "Entity Extraction" ? "active" : "")
                  }
                  onClick={() => {}}
                >
                  <Link
                    to="#!"
                    className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setselectePrompts("Entity Extraction");
                      setselectePromptsDesc(
                        "Extract the entities that are supported by spacy NER from this text"
                      );
                    }}
                  >
                    <i className="fa fa-file " style={{ width: "22px" }}></i>
                    <span style={{ lineHeight: "25px", height: "25px" }}>
                      Entity Extraction
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    "noactive  " +
                    (selectePrompts === "Chatbot" ? "active" : "")
                  }
                  onClick={() => {}}
                >
                  <Link
                    to="#!"
                    className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setselectePrompts("Chatbot");
                      setselectePromptsDesc(
                        `You are a chatbot capable of answering user queries using simple and easy-to-understand language.  your responsibility is to provide clear and straightforward responses to user questions, ensuring that complex concepts are explained in a straightforward manner. Aim for user-friendly interactions, making the information accessible to a wide audience regardless of their technical knowledge or expertise.`
                      );
                    }}
                  >
                    <i className="fa fa-file " style={{ width: "22px" }}></i>
                    <span style={{ lineHeight: "25px", height: "25px" }}>
                      Chatbot
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    "noactive  " +
                    (selectePrompts === "Text Summarization" ? "active" : "")
                  }
                  onClick={() => {}}
                >
                  <Link
                    to="#!"
                    className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setselectePrompts("Text Summarization");
                      setselectePromptsDesc(
                        `Summarize the provided text, capturing its main points, key ideas, and essential details. Ensure the summary is concise, coherent, and accurately represents the original content. Aim to preserve the core message and critical information while significantly reducing the length of the text. The summary should be clear and understandable to someone who has not read the original text.`
                      );
                    }}
                  >
                    <i className="fa fa-file " style={{ width: "22px" }}></i>
                    <span style={{ lineHeight: "25px", height: "25px" }}>
                      Text Summarization
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    "noactive  " +
                    (selectePrompts === "Time Line Conversion" ? "active" : "")
                  }
                  onClick={() => {}}
                >
                  <Link
                    to="#!"
                    className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setselectePrompts("Time Line Conversion");
                      setselectePromptsDesc(
                        `Create a timeline based on the provided text, identifying all significant dates and events. Against each date, provide concise summaries or highlights of the corresponding events mentioned in the text. Ensure the summaries capture the essential information related to each date, offering a clear and informative overview of the events, milestones, or developments discussed in the original text. The timeline should present a chronological sequence of events, making it easy for readers to understand the context and significance of each date.`
                      );
                    }}
                  >
                    <i className="fa fa-file " style={{ width: "22px" }}></i>
                    <span style={{ lineHeight: "25px", height: "25px" }}>
                      Time Line Conversion
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    "noactive  " +
                    (selectePrompts === "Topic Modeling" ? "active" : "")
                  }
                  onClick={() => {}}
                >
                  <Link
                    to="#!"
                    className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setselectePrompts("Topic Modeling");
                      setselectePromptsDesc(
                        `Identify and extract the main topics covered in the provided text. Focus on discerning the central themes, subjects, and key ideas discussed throughout the text. Aim to capture the primary focus and major points, ensuring that the extracted topics represent the core content comprehensively. The extracted topics should offer a clear overview of the main subjects addressed in the text, facilitating a quick understanding of its primary themes`
                      );
                    }}
                  >
                    <i className="fa fa-file " style={{ width: "22px" }}></i>
                    <span style={{ lineHeight: "25px", height: "25px" }}>
                      Topic Modeling
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    "noactive  " +
                    (selectePrompts === "Opinion mining" ? "active" : "")
                  }
                  onClick={() => {}}
                >
                  <Link
                    to="#!"
                    className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setselectePrompts("Opinion mining");
                      setselectePromptsDesc(
                        `Perform opinion mining on the provided text to extract sentiments, attitudes, and opinions expressed within the content. Identify and categorize opinions as positive, negative, or neutral, considering the context in which they are expressed. Focus on capturing the overall sentiment, specific viewpoints, and emotions conveyed by the author or speaker. Aim to discern the subjective elements and attitudes to gain insights into the opinions presented in the text. `
                      );
                    }}
                  >
                    <i className="fa fa-file " style={{ width: "22px" }}></i>
                    <span style={{ lineHeight: "25px", height: "25px" }}>
                      Opinion mining
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    "noactive  " +
                    (selectePrompts === "Text Classfication" ? "active" : "")
                  }
                  onClick={() => {}}
                >
                  <Link
                    to="#!"
                    className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setselectePrompts("Text Classfication");
                      setselectePromptsDesc(
                        `Develop a text classification model to categorize the given text into Sports, Politics ,Technology , Entertainment , Health ,Science , Finance , Art , Social categories. Consider the context and content of the text to determine the appropriate classification. The model should accurately assign the text to one of the specified categories, providing clear and reliable results. Focus on model's accuracy and efficiency in classifying similar texts in the future.`
                      );
                    }}
                  >
                    <i className="fa fa-file " style={{ width: "22px" }}></i>
                    <span style={{ lineHeight: "25px", height: "25px" }}>
                      Text Classfication
                    </span>
                  </Link>
                </li>
                <li
                  className={
                    "noactive  " +
                    (selectePrompts === "Personality Deduction" ? "active" : "")
                  }
                  onClick={() => {}}
                >
                  <Link
                    to="#!"
                    className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                    onClick={(e) => {
                      e.preventDefault();
                      setselectePrompts("Personality Deduction");
                      setselectePromptsDesc(
                        `Analyze the provided text and deduce the personality type of the author according to the MBTI (Myers-Briggs Type Indicator) classification system. MBTI categorizes individuals into 16 personality types based on four dichotomies: Introversion (I) vs. Extroversion (E), Sensing (S) vs. Intuition (N), Thinking (T) vs. Feeling (F), and Judging (J) vs. Perceiving (P).`
                      );
                    }}
                  >
                    <i className="fa fa-file " style={{ width: "22px" }}></i>
                    <span style={{ lineHeight: "25px", height: "25px" }}>
                      Personality Deduction
                    </span>
                  </Link>
                </li>
              </ul>
            </>
          ) : (
            <ul className="list-unstyled ml-0 dashboard-layout-sidebar sidebar-content-height ">
              {data.length > 0 &&
                data.map((ele, index) => {
                  return ele.is_folder ? (
                    folderStructure(ele, index)
                  ) : (
                    <li
                      className={
                        "noactive  " + (ele.isSelected ? "active" : "")
                      }
                      onClick={() => {}}
                      key={"o" + index}
                    >
                      <Link
                        to="#!"
                        className="px-6 py-0 my-0 font-size-3 font-weight-semibold flex-y-center"
                        onClick={async (e) => {
                          var dataz = [...data];
                          setmainSelected(false);
                          dataz = await data.map((item, ind) => {
                            if (index === ind) {
                              return {
                                ...item,
                                isSelected: true,
                              };
                            }
                            return {
                              ...item,
                              isSelected: false,
                              is_folder_open: false,
                            };
                          });
                          store.dispatch({
                            type: SET_FILE_DATA,
                            payload: dataz,
                          });
                          callConversations([], dataz[index], "", false, true);
                          sessionStorage.setItem(
                            "history",
                            JSON.stringify(dataz)
                          );

                          setTimeout(() => {
                            store.dispatch({
                              type: TRIGGER_CONVERSATION,
                              payload: true,
                            });
                          }, 50);
                        }}
                      >
                        <i
                          className="fa fa-file "
                          style={{ marginLeft: "15px", width: "22px" }}
                        ></i>
                        <span style={{ lineHeight: "25px", height: "25px" }}>
                          <Tooltip title={ele.file} placement="bottom">
                            {ele.file.substring(0, 20)}{" "}
                            {ele.file.length > 20 && "..."}
                          </Tooltip>
                        </span>
                        {isAuthenticated && ele.sync_required && (
                          <Tooltip title="Sync Required" placement="bottom">
                            <span
                              className="closeButton"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                syncFiles(ele.file_id);
                              }}
                              style={{ right: "40px", marginTop: "-3px" }}
                            >
                              <CloudSyncOutlined
                                className=""
                                style={{ fontSize: "10.5px" }}
                              />
                            </span>
                          </Tooltip>
                        )}
                        <Tooltip title="Process file" placement="bottom">
                          <span
                            className="closeButton"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              reDoProcessing(ele.file_id, true);
                            }}
                            style={{ right: "20px", marginTop: "-3px" }}
                          >
                            <RedoOutlined
                              className=""
                              style={{ fontSize: "10.5px" }}
                            />
                          </span>
                        </Tooltip>
                        {!ele.is_folder && (
                          <Popconfirm
                            placement="topRight"
                            title={"Delete file"}
                            description={"Are you sure?"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              clearTimeout(interval);
                              fileDelete(ele.file_id, [], "/", [...data]);
                              if (ele.isSelected) {
                                store.dispatch({
                                  type: CONVERSATION_DATA,
                                  payload: null,
                                });
                                clearTimeout(interval);
                              }
                            }}
                          >
                            <Tooltip title="Delete file" placement="bottom">
                              <span
                                className="closeButton"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                                style={{ marginTop: "-3px" }}
                              >
                                <CloseOutlined
                                  className=""
                                  style={{ fontSize: "10.5px" }}
                                />
                              </span>
                            </Tooltip>
                          </Popconfirm>
                        )}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          )}
        </div>
        <ul className="list-unstyled dashboard-layout-sidebar postion-sidebar">
          <li onClick={() => {}}>
            <Link
              to="#!"
              className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
              onClick={(e) => {
                e.preventDefault();
                if (isAuthenticated) {
                  setshowModal1(true);
                } else {
                  setshowModal(true);
                }
              }}
            >
              <i className="fas fa-tags mr-7" style={{ width: "16px" }}></i>
              Pricing
            </Link>
          </li>
          <li className="position-relative" onClick={() => {}}>
            <Popover
              placement="right"
              style={{ padding: "0px" }}
              content={
                <div className="" style={{ width: "140px" }}>
                  <ul className="list-unstyled dashboard-layout-sidebar mb-0 pb-0">
                    <li onClick={() => {}}>
                      <Link
                        to="/prompts"
                        className="px-5  py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
                      >
                        <i
                          className="fas fa-lightbulb mr-4"
                          style={{ width: "16px" }}
                        ></i>
                        Prompts
                      </Link>
                    </li>
                    <li onClick={() => {}}>
                      <Link
                        to="/faqs"
                        className="px-3  my-2 font-size-3 font-weight-semibold flex-y-center"
                      >
                        <i
                          className="fas fa-info-circle mr-7"
                          style={{ width: "16px" }}
                        ></i>
                        FAQs
                      </Link>
                    </li>
                  </ul>
                </div>
              }
            >
              <Link
                to="#!"
                className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
              >
                <i
                  className="fas fa-question-circle mr-7"
                  style={{ width: "16px" }}
                ></i>
                Help
              </Link>
            </Popover>
            <i
              className="fas fa-caret-right  mr-5"
              style={{
                width: "16px",
                position: "absolute",
                right: "0px",
                top: "8px",
              }}
            ></i>
          </li>
          <li onClick={() => {}} style={{ display: "none" }}>
            <Dropdown menu={menuProps}>
              <Link
                to="#!"
                className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
              >
                <i
                  className="fas fa-shield-alt mr-7"
                  style={{ width: "16px" }}
                ></i>
                Go Private <DownOutlined className="ml-auto" />
              </Link>
            </Dropdown>
          </li>
          {isAuthenticated ? (
            <>
              <li onClick={() => {}}>
                <Link
                  to="#!"
                  className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
                  onClick={(e) => {
                    e.preventDefault();
                    if (nextCloud.is_logged_in) {
                      window.open(
                        "https://core.sigmatext.ai/dms/apps/dashboard/",
                        "_blank"
                      );
                    } else {
                      window.open(nextCloud.login_url, "_blank");
                    }
                    dmsStatus(true);
                  }}
                >
                  <CloudUploadOutlined
                    className="mr-7 font-size-4"
                    style={{ width: "16px" }}
                  />
                  Drive
                </Link>
              </li>
              <li onClick={() => {}}>
                <Link
                  to="#!"
                  className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
                  onClick={(e) => {
                    e.preventDefault();
                    signout();
                  }}
                >
                  <LogoutOutlined
                    className="mr-7 font-size-4"
                    style={{ width: "16px" }}
                  />
                  Signout
                </Link>
              </li>
            </>
          ) : (
            <li onClick={() => {}}>
              <Link
                to="#!"
                className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
                onClick={(e) => {
                  e.preventDefault();
                  setshowModal(true);
                }}
              >
                <LoginOutlined
                  className="mr-7 font-size-4"
                  style={{ width: "16px" }}
                />
                Signin
              </Link>
            </li>
          )}
          <li>
            <Link
              to="/"
              className="px-6 py-0 my-2 font-size-3 font-weight-semibold flex-y-center"
              onClick={(e) => {
                e.preventDefault();
                if (window.location.href.includes("/education")) {
                  history.push("/education");
                } else {
                  history.push("/");
                }
              }}
            >
              <i
                className="fas fa-arrow-left mr-7"
                style={{ width: "16px" }}
              ></i>
              Go Back Home
            </Link>
          </li>
        </ul>
      </div>
      <Link
        className="sidebar-mobile-button"
        data-toggle="collapse"
        to="#!"
        role="button"
        aria-expanded="false"
        aria-controls="sidebar"
      >
        <i className="icon icon-sidebar-2"></i>
      </Link>
    </div>
  );
};
SideBar.propTypes = {
  isAuthenticated: PropTypes.any,
  data: PropTypes.any,
  uploadInit: PropTypes.any,
  uploadUrl: PropTypes.any,
  isError: PropTypes.any,
  isErrorType: PropTypes.any,
  errMessage: PropTypes.any,
  tirggerApi: PropTypes.any,
  fileDelete: PropTypes.any,
  uploadInitMulti: PropTypes.any,
  listFolderChats: PropTypes.func,
  reDoProcessing: PropTypes.func,
  nextCloud: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isError: state.upload.isError,
  isErrorType: state.upload.isErrorType,
  errMessage: state.upload.errMessage,
  isAuthenticated: state.auth.isAuthenticated,
  data: state.upload.data,
  tirggerApi: state.upload.trigger,
  nextCloud: state.auth.nextCloud,
});
export default connect(mapStateToProps, {
  uploadInit,
  listChatHistory,
  fileDelete,
  uploadUrl,
  uploadInitMulti,
  listFolderChats,
  reDoProcessing,
  dmsStatus,
  syncFiles,
  signout,
})(SideBar);
