import React from 'react';

const LandingPage = () => {
  return (
    <div>
      <div className='bg-gradient-1 pt-26 pt-md-32 pt-lg-33 pt-xl-31 position-relative z-index-1 overflow-hidden bg-white  pb-10'>
        <div className='pos-abs-tr w-50 z-index-n2'>
          <img
            src='/assets/image/patterns/hero-pattern.png'
            alt=''
            className='gr-opacity-1'
          />
        </div>
        <div className='container'>
          <div className='row position-relative '>
            <div className='col-lg-10 pt-lg-18 pb-lg-26 pb-xl-26 pb-md-26 pb-10'>
              <h1 className='font-size-11 mb-8 text-capitalized'>
                About us <br />
              </h1>
              <p className='font-size-6'>
                We are a team of visionary engineers and designers who are
                committed to producing smart and ingenious solutions that defy
                conventional norms. Our approach involves continual
                experimentation with novel technologies and techniques to ensure
                that we remain at the forefront of our field. We prioritize
                critical thinking and foster an environment where our team
                members are encouraged to challenge assumptions and innovate
                beyond established boundaries. Our ultimate goal is to discover
                innovative ways to enhance and revolutionize traditional
                industries.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className='pt-13 pt-lg-30 pb-lg-10 bg-default '>
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div
              className='col-xl-6 col-lg-6 col-md-8 col-xs-10 aos-init aos-animate'
              data-aos='fade-right'
              data-aos-duration='800'
              data-aos-once='true'
            >
              <div className='position-relative px-xl-20 pr-md-15 pr-9'>
                <img
                  src='/assets/image/story.jpg'
                  alt=''
                  className='w-100 rounded-4'
                />
              </div>
            </div>
            <div
              className='col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-11 aos-init aos-animate'
              data-aos='fade-left'
              data-aos-duration='800'
              data-aos-once='true'
            >
              <div className='content-2 pl-xl-10 d-flex flex-column justify-content-center h-100 pt-lg-0 pt-15 pr-xl-10 pr-xxl-0'>
                <h2 className='font-size-8 mb-7 pr-xs-13  pr-md-0 pr-sm-8'>
                  Our Story
                </h2>
                <p className='text-default-color font-size-5 mb-7 mb-lg-12 pr-xxl-13 pr-lg-0 pr-md-10'>
                  We believe that in order to thrive in the post-COVID world,
                  companies must adopt a digital-first approach. We believe that
                  open source is the best way to harness the collective
                  knowledge of the modern world, and we are committed to helping
                  our clients transform into their digital selves using the
                  latest tools and techniques. <br /> <br />​ Our mission is to
                  help companies of all sizes implement best practices in
                  software development in a cost-effective, reliable, and timely
                  manner.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pt-13 pt-lg-15 pb-lg-20 bg-default'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div
              className='col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-10 order-2 order-lg-1 aos-init aos-animate'
              data-aos='fade-right'
              data-aos-duration='800'
              data-aos-once='true'
            >
              <div className='position-relative d-flex flex-column justify-content-center h-100 pr-xl-8 pr-0 mt-5 mt-lg-0'>
                <h2 className='font-size-8 mb-7 pr-xs-13  pr-md-0 pr-sm-8'>
                  Experienced Leadership
                </h2>
                <p className='text-default-color font-size-5 mb-7 mb-lg-12 pr-xxl-13 pr-lg-0 pr-md-10'>
                  At our company, we are proud to have a team of experts who are
                  the best in their field. With years of experience and a
                  passion for their work, our team members are dedicated to
                  delivering exceptional results for our clients. Whether you
                  need assistance with software development, data science, or
                  cloud computing, our team has the knowledge and expertise to
                  help you succeed.
                </p>
              </div>
            </div>
            <div
              className='col-lg-6 col-md-8 order-1 order-lg-2 aos-init aos-animate'
              data-aos='fade-left'
              data-aos-duration='800'
              data-aos-once='true'
            >
              <div className='pl-lg-10 pl-0'>
                <div className='d-xs-flex  mx-sm-n3'>
                  <div className='position-relative px-xl-15 pl-md-15 pr-9'>
                    <img
                      src='/assets/image/leadership.jpg'
                      alt=''
                      className='w-100 rounded-4'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
