import {
  RESET_UPLOAD,
  ALERT_UPLOAD,
  UPLOADING_FILE,
  SET_FILE_ID,
  SET_FILE_DATA,
  ALERT_CHAT,
  LOGIN_RESET,
  TRIGGER_CONVERSATION,
  TYPING_MESSAGE,
  UPLOADING_MULTI_FILES,
  CONVERSATION_DATA,
  SEARCH_DATA,
  RESET_CHAT,
} from "../Action/types";

const initialState = {
  loading: false,
  isError: false,
  isErrorType: null,
  errMessage: null,
  isErrorChat: false,
  isErrorTypeChat: null,
  errMessageChat: null,
  isUploading: false,
  data: [],
  SearchData: [],
  file_id: "",
  file_name: "",
  trigger: false,
  typing: false,
  multiFileStatus: false,
  succeedCount: 0,
  failedCount: 0,
  totalCount: 0,
  conversationData: {},
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_DATA:
      return {
        ...state,
        SearchData: payload,
      };
    case TYPING_MESSAGE:
      return {
        ...state,
        typing: payload,
      };
    case SET_FILE_ID:
      return {
        ...state,
        file_id: payload,
      };
    case CONVERSATION_DATA:
      return {
        ...state,
        conversationData: payload,
      };
    case TRIGGER_CONVERSATION:
      return {
        ...state,
        trigger: payload,
      };
    case UPLOADING_MULTI_FILES:
      return {
        ...state,
        multiFileStatus: payload.status,
        succeedCount: payload.succeedCount,
        totalCount: payload.totalCount,
        failedCount: payload.failedCount,
      };
    case SET_FILE_DATA:
      return {
        ...state,
        data: payload,
      };
    case UPLOADING_FILE:
      return {
        ...state,
        isUploading: payload,
      };
    case ALERT_UPLOAD:
      return {
        ...state,
        isError: payload.isError,
        isErrorType: payload.isErrorType,
        errMessage: payload.errMessage,
      };
    case ALERT_CHAT:
      return {
        ...state,
        isErrorChat: payload.isError,
        isErrorTypeChat: payload.isErrorType,
        errMessageChat: payload.errMessage,
      };

    case RESET_CHAT:
      return {
        ...state,
        isErrorChat: false,
        isErrorTypeChat: null,
        errMessageChat: null,
      };
    case LOGIN_RESET:
      return {
        ...state,
        loading: false,
        isError: false,
        isErrorType: null,
        errMessage: null,
        isErrorChat: false,
        isErrorTypeChat: null,
        errMessageChat: null,
        isUploading: false,
        data: [],
        file_id: "",
        file_name: "",
      };
    case RESET_UPLOAD:
      return {
        ...state,
        isError: false,
        isErrorType: null,
        errMessage: null,
      };
    default:
      return state;
  }
}

export default authReducer;
