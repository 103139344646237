import { message as msg } from 'antd';
import axios from 'axios';
import React, { useState } from 'react';
import { BACKEND_URL } from '../../../Action/types';

const Private = () => {
  const [name, setname] = useState('');
  const [email, setemail] = useState('');
  const [subject, setsubject] = useState('');
  const [message, setmessage] = useState('');

  const sendMail = async () => {
    if (email !== '') {
      const config = {
        'Content-Type': 'application/json',
      };
      const body = { name, email, subject, message, query_type: 'private gpt' };
      await axios({
        method: 'POST',
        url: BACKEND_URL + 'customer/query',
        data: body,
        headers: config,
      })
        .then((response) => {
          if (response.data.success) {
            msg.success('Query submitted. Will get back to you soon.');
            setname('');
            setemail('');
            setmessage('');
            setsubject('');
          } else {
            msg.success('Something went wrong! Please try again.');
          }
        })
        .catch((error) => {
          msg.success('Something went wrong! Please try again.');
        });
    } else {
      msg.error('Please add email');
    }
  };
  return (
    <div>
      <section className='pt-15 pt-lg-30 pb-7 pb-lg-22 bg-default'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div
              className='col-xxl-8 col-lg-9 col-md-10 aos-init aos-animate'
              data-aos='fade-in'
              data-aos-duration='1000'
              data-aos-once='true'
            >
              <div className='section-title text-center mb-10 mb-lg-12 mb-lg-10 pb-lg-9 pb-0'>
                <h1 className='font-size-9 mb-6'>
                  Why Your Company Needs PrivateGPT, an LLM Hosted on Your Own
                  Server
                </h1>
                <p className='text-default-color font-size-6 px-5 px-md-10 px-lg-15 px-xl-24 px-xxl-22'>
                  Enhanced Security, Control, and Customization with unlimited
                  access to all the knwoledge that your organisation has
                  accumulated over time.
                </p>
              </div>
            </div>
          </div>
          <div className='row pb-lg-6 justify-content-center'>
            <div
              className='col-xl-6 col-lg-5 col-md-10 col-sm-11'
              data-aos='fade-right'
              data-aos-duration='800'
              data-aos-once='true'
            >
              <div className='position-relative pr-xl-20 pr-md-15 pr-15'>
                <img
                  src='/assets/images/bg-4.jpg'
                  alt=''
                  className='w-100 rounded overflow-hidden'
                />
              </div>
            </div>
            <div
              className='col-xl-6 col-lg-7 col-md-8'
              data-aos='fade-left'
              data-aos-duration='800'
              data-aos-once='true'
            >
              <div className='pl-lg-10 pl-0 d-flex flex-column justify-content-center h-100 pt-lg-0 pt-15 pr-md-13 pr-xl-15 pr-xxl-25 pr-0 '>
                <h2
                  className='font-size-8 mb-6 pr-xxl-15 '
                  style={{ lineHeight: '45px' }}
                >
                  All of us have seen the power of Chat GPT but are -
                </h2>
                <p className='text-gray font-size-5 mb-6 mb-lg-10'></p>
                <ul className='list-unstyled pl-0'>
                  <li className='fontBold400 border-0 d-flex mb-7 heading-default-color'>
                    <i className='fas fa-check font-size-4 text-green mr-6'></i>
                    Worried about data sharing outside your company resources.
                  </li>
                  <li className='fontBold400 border-0 d-flex mb-7 heading-default-color'>
                    <i className='fas fa-check font-size-4 text-green mr-6'></i>
                    Woried that unauthorised users can access your knowledge
                    base eroding competitive edge.
                  </li>
                  <li className='fontBold400 border-0 d-flex mb-7 heading-default-color'>
                    <i className='fas fa-check font-size-4 text-green mr-6'></i>
                    Not happy with PublicGPT as they lack the upto date
                    information.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pt-2 pt-lg-0 pb-lg-20 bg-default'>
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div
              className='col-xl-10 col-md-11'
              data-aos='fade-left'
              data-aos-duration='800'
              data-aos-once='true'
            >
              <div className='faq-content pt-lg-4 pt-6'>
                <div
                  className='accordion rounded-10 border-green border-top-5 pl-1'
                  id='accordionExample'
                >
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-1'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-1'
                        aria-expanded='true'
                        aria-controls='collapse2-1'
                      >
                        What is a private LLM, and how does it differ from
                        public models?
                      </button>
                    </div>
                    <div
                      id='collapse2-1'
                      className='collapse pr-7 show'
                      aria-labelledby='heading2-1'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        A PrivateGPT, also known as PrivateLLM, is an advanced
                        AI tool developed and customized specifically for use
                        within a particular organization. It is designed to work
                        with the information and knowledge that the organization
                        possesses, catering exclusively to the users within that
                        organization. The main difference between a private LLM
                        and public models lies in their accessibility and
                        purpose. Public models, like OpenAI's ChatGPT, are
                        general-purpose models that are available to everyone.
                        They aim to encompass a wide range of knowledge and
                        cater to diverse applications. On the other hand, a
                        private LLM is tailored to a specific organization's
                        needs and focuses on utilizing the data and expertise
                        within that organization. It is not openly accessible to
                        the public. Private LLMs are trained using the
                        organization's proprietary data, which can include
                        documents, emails, databases, and other structured or
                        unstructured information. By leveraging the
                        organization's data, a private LLM can provide more
                        accurate and relevant responses or insights related to
                        the organization's specific domain or industry. It can
                        assist in tasks such as answering queries, generating
                        reports, translating languages, or even creating content
                        specific to the organization's requirements.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-2'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-2'
                        aria-expanded='false'
                        aria-controls='collapse2-2'
                      >
                        What are the benefits of using a private LLM within my
                        organization?
                      </button>
                    </div>
                    <div
                      id='collapse2-2'
                      className='collapse pr-7'
                      aria-labelledby='heading2-2'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        Using a private LLM (Large Language Model) within your
                        organization offers several benefits:
                        <b>Enhanced Knowledge Management</b>: A private LLM can
                        efficiently sift through and organize vast amounts of
                        unstructured data within your organization. It helps
                        employees access crucial insights faster, enabling them
                        to make informed decisions based on relevant
                        information.
                        <b>Improved Customer Experience</b>: By leveraging
                        natural language processing (NLP), a private LLM can
                        handle customer queries and support requests
                        effectively. It provides personalized and contextually
                        relevant responses, enhancing the overall customer
                        experience and satisfaction.
                        <b>Accelerated Innovation</b>: A private LLM can assist
                        in generating creative ideas and identifying potential
                        growth opportunities for your organization. By
                        leveraging its language processing capabilities, it
                        empowers your teams to stay ahead in competitive markets
                        and drive innovation.
                        <b>Increased Productivity</b>: Integrating a private LLM
                        into your employees' workflows can significantly boost
                        productivity. It speeds up the delivery of tasks,
                        automates repetitive processes, and reduces manual
                        effort, allowing your employees to focus on high-value
                        activities. This, in turn, creates a less stressful work
                        environment and improves overall productivity.
                        <b>Data Privacy and Security</b>: Using a private LLM
                        ensures optimal data privacy and security within your
                        organization. Since it is tailored to your specific
                        needs, it operates within your own infrastructure,
                        keeping your sensitive information within your control.
                        This mitigates the risks associated with transmitting
                        data to external providers and helps you comply with
                        data protection regulations.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-3'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-3'
                        aria-expanded='false'
                        aria-controls='collapse2-3'
                      >
                        How can a private LLM improve knowledge management and
                        information retrieval?
                      </button>
                    </div>
                    <div
                      id='collapse2-3'
                      className='collapse pr-7'
                      aria-labelledby='heading2-3'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        A private LLM (Large Language Model) can greatly improve
                        knowledge management and information retrieval within
                        your organization. Here's how:
                        <b>Efficient Data Organization</b>: A private LLM has
                        the ability to automatically sift through vast amounts
                        of unstructured data, such as documents, emails, and
                        databases. It can analyze and categorize this data,
                        making it easier to organize and manage information
                        effectively.
                        <b>Quick Access to Insights</b>: With a private LLM,
                        employees can access crucial insights and information
                        more quickly. By asking specific questions or providing
                        keywords, the LLM can retrieve relevant and accurate
                        responses from the available data, saving time and
                        effort in searching for information manually.
                        <b>Natural Language Understanding</b>: Private LLMs are
                        equipped with advanced natural language processing
                        capabilities. They can understand and interpret human
                        language, allowing users to interact with the system
                        using everyday language instead of complex search
                        queries. This makes information retrieval more intuitive
                        and user-friendly.
                        <b>Contextual Relevance</b>: A private LLM can provide
                        contextually relevant information based on the user's
                        query. It understands the context of the question and
                        can deliver precise and tailored responses, taking into
                        account the specific needs and requirements of the user.
                        <b>Personalized Knowledge Base</b>: By leveraging a
                        private LLM, you can create a personalized knowledge
                        base specific to your organization. The LLM can learn
                        from your organization's data and accumulate knowledge
                        over time. This ensures that the information retrieved
                        is highly relevant and aligned with your organization's
                        unique expertise and domain.
                        <b>Continuous Learning</b>: Private LLMs have the
                        ability to learn and adapt to new information. As your
                        organization's data and knowledge grow, the LLM can
                        continuously update its understanding and incorporate
                        the latest insights into its responses. This ensures
                        that the information retrieved remains up to date and
                        reflects the most current knowledge within your
                        organization.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-4'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-4'
                        aria-expanded='false'
                        aria-controls='collapse2-4'
                      >
                        What security measures are in place to protect sensitive
                        data when using a private LLM?
                      </button>
                    </div>
                    <div
                      id='collapse2-4'
                      className='collapse pr-7'
                      aria-labelledby='heading2-4'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        When using a private LLM (Large Language Model), several
                        security measures are in place to protect sensitive
                        data. These measures ensure that your organization's
                        information remains confidential and safeguarded. Here
                        are some key security measures:
                        <b>Data Access Control</b>: Access to sensitive data is
                        strictly controlled. Unauthorized individuals are
                        prevented from retrieving or accessing confidential
                        information when querying the LLM. This is achieved by
                        implementing robust access control policies.
                        <b>Data Encryption</b>: All data, whether stored or
                        transmitted, is protected through strong encryption
                        methods. Encryption converts the data into a format that
                        can only be accessed with the appropriate decryption
                        key. This prevents unauthorized access and maintains the
                        confidentiality of sensitive information.
                        <b>User Authentication</b>: To ensure only authorized
                        personnel can access the private LLM, user
                        authentication mechanisms are employed. This requires
                        users to provide valid credentials, such as usernames
                        and passwords, before they can interact with the system.
                        User authentication helps verify the identity of
                        individuals accessing the LLM and adds an extra layer of
                        security.
                        <b>Regular Audits</b>: Routine audits and evaluations
                        are conducted to identify and address potential
                        vulnerabilities. These audits assess the security of the
                        private LLM and its underlying systems, helping to
                        detect any weaknesses or potential threats. By regularly
                        evaluating and improving security measures,
                        organizations can stay proactive in maintaining a secure
                        environment.
                        <b>Compliance with Regulations</b>: Private LLM
                        implementations adhere to applicable data protection
                        laws and regulations. This includes following guidelines
                        outlined in regulations like the General Data Protection
                        Regulation (GDPR) and industry-specific standards such
                        as the Health Insurance Portability and Accountability
                        Act (HIPAA) or Payment Card Industry Data Security
                        Standard (PCI-DSS). Compliance ensures that data
                        handling practices align with legal requirements and
                        industry best practices. By implementing data access
                        control, encryption, user authentication, regular
                        audits, and compliance with regulations, organizations
                        can establish a secure environment when using a private
                        LLM. These security measures work together to protect
                        sensitive data, mitigate risks, and maintain the
                        confidentiality and integrity of information within the
                        organization.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-5'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-5'
                        aria-expanded='false'
                        aria-controls='collapse2-5'
                      >
                        Can a private LLM be customized or trained with
                        company-specific data?
                      </button>
                    </div>
                    <div
                      id='collapse2-5'
                      className='collapse pr-7'
                      aria-labelledby='heading2-5'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        Yes, a private LLM (Large Language Model) can be
                        customized and trained with company-specific data. This
                        is one of the advantages of using a private LLM within
                        your organization. By leveraging your company's own
                        data, the LLM can be tailored to understand and generate
                        content specific to your industry, domain, or business
                        needs. To customize a private LLM, you can provide it
                        with your company's proprietary documents, emails,
                        databases, and other relevant information sources. This
                        allows the LLM to learn from and gain knowledge about
                        your specific organization. As the LLM processes and
                        analyzes this data, it becomes better equipped to
                        generate accurate and contextually relevant responses or
                        insights. Training a private LLM with company-specific
                        data enhances its ability to understand and address the
                        unique challenges, terminology, and requirements of your
                        organization. It enables the LLM to provide more
                        accurate and personalized responses, making it a
                        valuable tool for various tasks, such as knowledge
                        management, customer support, or generating
                        industry-specific content. It's important to note that
                        training a private LLM requires careful consideration of
                        data privacy and security. Your organization needs to
                        ensure that sensitive information is handled
                        appropriately and that proper data access controls are
                        in place to protect confidential data. By adhering to
                        best practices, you can effectively customize and train
                        a private LLM with company-specific data, unlocking its
                        full potential to benefit your organization.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-6'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-6'
                        aria-expanded='false'
                        aria-controls='collapse2-6'
                      >
                        What are the technical requirements for implementing a
                        private LLM?
                      </button>
                    </div>
                    <div
                      id='collapse2-6'
                      className='collapse pr-7'
                      aria-labelledby='heading2-6'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        Implementing a private LLM (Large Language Model)
                        requires certain technical requirements to ensure its
                        successful deployment within your organization. Here are
                        some key considerations:
                        <b>Computing Resources</b>: You'll need sufficient
                        computing resources, including processing power and
                        memory, to support the private LLM's operations.
                        Depending on the size and complexity of the LLM, this
                        may involve powerful servers or cloud-based
                        infrastructure.
                        <b>Data Storage</b>: Since a private LLM processes and
                        learns from data, you'll need adequate storage capacity
                        to store the LLM's training data, model parameters, and
                        any additional datasets relevant to your organization.
                        <b>Training Infrastructure</b>: Training a private LLM
                        may involve extensive computational resources,
                        especially during the initial training phase. This
                        includes GPUs or TPUs (Tensor Processing Units) to
                        accelerate the training process and reduce time
                        requirements.
                        <b>Integration with Existing Systems</b>: To make the
                        most of a private LLM, it's important to integrate it
                        with your organization's existing systems and workflows.
                        This may involve connecting the LLM with databases,
                        knowledge bases, communication platforms (such as Slack
                        or Microsoft Teams), or other relevant software
                        applications.
                        <b>Data Privacy and Security Measures</b>: Implementing
                        a private LLM requires robust data privacy and security
                        measures. This includes encryption of data both at rest
                        and in transit, secure authentication mechanisms, access
                        control policies, and adherence to regulatory
                        requirements to protect sensitive information.
                        <b>Scalability</b>: Consider the scalability
                        requirements of your organization. Will the private LLM
                        need to handle increasing volumes of data or user
                        queries over time? Ensuring scalability allows the LLM
                        to grow alongside your organization's needs.
                        <b>Monitoring and Maintenance</b>: Establish mechanisms
                        for monitoring the performance and health of the private
                        LLM. This involves regular maintenance, updates, and
                        debugging to address any issues that may arise and
                        ensure optimal functionality. It's worth noting that the
                        specific technical requirements may vary depending on
                        the particular private LLM implementation and the scale
                        of your organization. Consulting with AI experts and
                        considering your organization's unique needs will help
                        determine the most suitable technical infrastructure for
                        implementing a private LLM successfully.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-7'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-7'
                        aria-expanded='false'
                        aria-controls='collapse2-7'
                      >
                        How does a private LLM handle user access control and
                        permissions?
                      </button>
                    </div>
                    <div
                      id='collapse2-7'
                      className='collapse pr-7'
                      aria-labelledby='heading2-7'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        A private LLM (Large Language Model) implements user
                        access control and permissions to ensure that only
                        authorized individuals can access specific information
                        and functionalities. Here's how it typically handles
                        user access control:
                        <b>User Authentication</b>: Users are required to
                        authenticate themselves before accessing the private
                        LLM. This involves providing valid credentials such as
                        usernames and passwords or utilizing other
                        authentication methods like multi-factor authentication
                        (MFA) to enhance security.
                        <b>Role-Based Access Control (RBAC)</b>: The private LLM
                        employs RBAC to define roles and assign specific
                        permissions to each role. Different roles may include
                        administrators, managers, or regular users, each with
                        varying levels of access and privileges.
                        <b>Access Permissions</b>: Within the private LLM,
                        access permissions are set to control what actions or
                        data users can interact with. These permissions can be
                        fine-grained, allowing administrators to specify access
                        rights at a granular level based on the user's role,
                        department, or other criteria.
                        <b>Data Segmentation</b>: In some cases, the private LLM
                        may implement data segmentation, separating data into
                        different categories or compartments. This ensures that
                        users can only access the data relevant to their roles
                        or areas of responsibility, preventing unauthorized
                        access to sensitive information.
                        <b>Audit Logs</b>: The private LLM maintains audit logs
                        that track user activities, including login attempts,
                        data access, and system modifications. These logs help
                        monitor user actions, detect potential security
                        breaches, and provide an audit trail for compliance and
                        accountability purposes.
                        <b>Regular Reviews and Updates</b>: User access control
                        and permissions should be regularly reviewed and
                        updated. As user roles change within the organization or
                        when new features or data are added to the private LLM,
                        access control configurations may need to be adjusted to
                        maintain appropriate access levels and security. By
                        implementing these measures, a private LLM ensures that
                        users have access to the information and functionalities
                        they need while maintaining strict control over who can
                        access sensitive data or perform certain actions. This
                        helps protect confidential information, maintain data
                        privacy, and mitigate the risk of unauthorized access or
                        misuse of the private LLM system.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-8'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-8'
                        aria-expanded='false'
                        aria-controls='collapse2-8'
                      >
                        What level of privacy and compliance can be ensured when
                        using a private LLM?
                      </button>
                    </div>
                    <div
                      id='collapse2-8'
                      className='collapse pr-7'
                      aria-labelledby='heading2-8'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        When using a private LLM (Large Language Model),
                        organizations can achieve a high level of privacy and
                        compliance. Here's how privacy and compliance are
                        ensured:
                        <b>Data Privacy</b>: A private LLM prioritizes data
                        privacy by keeping sensitive information within the
                        organization. Unlike public models, it operates within
                        the organization's infrastructure, ensuring that data
                        remains under the organization's control. This reduces
                        the risk of data exposure or unauthorized access.
                        <b>Confidentiality</b>: Private LLMs handle sensitive
                        data securely, maintaining confidentiality throughout
                        the process. Robust encryption methods are used to
                        protect data both during storage and transmission,
                        preventing unauthorized access or changes to the
                        information.
                        <b>Compliance with Regulations</b>: Private LLMs can be
                        designed and configured to comply with relevant data
                        protection regulations and industry-specific standards.
                        This includes regulations like the General Data
                        Protection Regulation (GDPR), the California Consumer
                        Privacy Act (CCPA), and standards such as the Health
                        Insurance Portability and Accountability Act (HIPAA) or
                        the Payment Card Industry Data Security Standard
                        (PCI-DSS).
                        <b>Access Control</b>: Strict access control measures
                        are implemented to ensure that only authorized
                        individuals can access the private LLM and its
                        associated data. User authentication, role-based access
                        control, and fine-grained permissions help prevent
                        unauthorized access and maintain data privacy.
                        <b>Auditability</b>: Private LLMs maintain audit logs
                        that track user activities, data access, and system
                        modifications. These logs enable organizations to
                        monitor and review actions, detect any anomalies or
                        potential security breaches, and demonstrate compliance
                        during audits or regulatory inspections.
                        <b>Customization and Control</b>: With a private LLM,
                        organizations have greater customization and control
                        over the model's behavior and the data it processes.
                        This allows them to align the LLM's capabilities with
                        their privacy and compliance requirements, ensuring that
                        the model adheres to the organization's data governance
                        policies. By implementing these privacy and compliance
                        measures, a private LLM provides organizations with a
                        robust framework to protect sensitive data, maintain
                        confidentiality, and meet regulatory obligations. It
                        allows organizations to leverage the power of AI-driven
                        language models while ensuring the privacy and
                        compliance of their data.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-9'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-9'
                        aria-expanded='false'
                        aria-controls='collapse2-9'
                      >
                        Are there any legal or ethical considerations associated
                        with deploying a private LLM?
                      </button>
                    </div>
                    <div
                      id='collapse2-9'
                      className='collapse pr-7'
                      aria-labelledby='heading2-9'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        Deploying a private LLM (Large Language Model) comes
                        with legal and ethical considerations that organizations
                        need to be mindful of. Here are some important points to
                        consider:
                        <b>Data Privacy and Consent</b>: Organizations must
                        ensure that they have the legal right to use the data
                        that is fed into the LLM. This includes obtaining
                        appropriate consent from individuals whose data may be
                        processed by the model. Compliance with data protection
                        laws, such as the General Data Protection Regulation
                        (GDPR), is crucial.
                        <b>Fairness and Bias</b>: LLMs learn from the data they
                        are trained on, which means they can inherit biases
                        present in the training data. Organizations should be
                        aware of the potential for bias and take steps to
                        address and mitigate it. They should strive to ensure
                        fairness and avoid discrimination in the outputs
                        generated by the LLM.
                        <b>Intellectual Property Rights</b>: Organizations
                        should respect intellectual property rights and avoid
                        infringing on copyrights or proprietary information when
                        using a private LLM. Care should be taken to ensure that
                        the model does not generate or distribute content that
                        violates intellectual property laws.
                        <b>Transparency and Explainability</b>: It is important
                        to consider the transparency and explainability of the
                        LLM's outputs. Organizations should be able to
                        understand and explain the reasoning behind the model's
                        responses. This is particularly relevant in cases where
                        the LLM interacts with customers or makes important
                        decisions that impact individuals' lives.
                        <b>Liability and Accountability</b>: Organizations need
                        to clarify the liability and accountability framework
                        associated with using a private LLM. They should be
                        aware of the potential risks and ensure that appropriate
                        measures are in place to handle any errors or unintended
                        consequences that may arise from the LLM's outputs or
                        actions.
                        <b>Ethical Guidelines and Frameworks</b>: Organizations
                        should establish clear ethical guidelines for the use of
                        LLMs within their operations. These guidelines can help
                        ensure responsible and ethical use of the technology,
                        considering factors such as privacy, fairness,
                        transparency, and accountability.
                        <b>Compliance with Regulations</b>: Organizations must
                        comply with relevant laws, regulations, and
                        industry-specific standards when deploying a private
                        LLM. This includes adhering to data protection laws,
                        consumer protection regulations, and any sector-specific
                        guidelines that apply. By considering these legal and
                        ethical considerations, organizations can deploy a
                        private LLM in a responsible and compliant manner,
                        fostering trust, transparency, and accountability in
                        their use of AI technology.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-10'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-10'
                        aria-expanded='false'
                        aria-controls='collapse2-10'
                      >
                        How can a private LLM integrate with existing systems
                        and workflows within the organization?
                      </button>
                    </div>
                    <div
                      id='collapse2-10'
                      className='collapse pr-7'
                      aria-labelledby='heading2-10'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        Integrating a private LLM (Large Language Model) with
                        existing systems and workflows within an organization
                        can be achieved through the following steps:
                        <b>API Integration</b>: Private LLMs often provide
                        application programming interfaces (APIs) that allow
                        seamless integration with existing systems. These APIs
                        enable communication between the LLM and other software
                        applications or databases used within the organization.
                        By leveraging these APIs, the LLM can be accessed and
                        utilized within familiar workflows.
                        <b>Data Integration</b>: To make the most of a private
                        LLM, organizations can integrate it with their existing
                        data sources. This involves connecting the LLM to
                        databases, document repositories, or other data storage
                        systems where relevant information is stored. By doing
                        so, the LLM can access and analyze data from these
                        sources, enhancing its ability to provide accurate and
                        insightful responses.
                        <b>Customization and Tailoring</b>: A private LLM can be
                        customized to align with specific organizational
                        requirements and workflows. This may involve configuring
                        the LLM to understand domain-specific terminology,
                        adapting its outputs to match organizational
                        preferences, or training it with company-specific data
                        to improve accuracy and relevance. Customization ensures
                        that the LLM becomes an integral part of existing
                        systems and workflows, seamlessly fitting into the
                        organization's operations.
                        <b>Workflow Integration</b>: To maximize efficiency, the
                        LLM can be integrated into existing workflows, enabling
                        users to leverage its capabilities without disrupting
                        their established processes. For example, the LLM can be
                        incorporated into content creation workflows, customer
                        support systems, or knowledge management processes. This
                        integration allows employees to access the LLM's
                        insights, suggestions, or automated responses within
                        their familiar tools and platforms.
                        <b>User Training and Adoption</b>: Organizations should
                        provide training and support to users to facilitate the
                        integration of the private LLM into their workflows.
                        This includes educating employees on how to effectively
                        interact with the LLM, interpret its outputs, and
                        integrate its insights into their decision-making
                        processes. User adoption and familiarity with the LLM
                        are crucial for successful integration and optimal
                        utilization of its capabilities. By following these
                        steps, organizations can integrate a private LLM
                        smoothly into their existing systems and workflows,
                        enhancing efficiency, productivity, and decision-making
                        processes across the organization.
                      </div>
                    </div>
                  </div>

                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-11'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-11'
                        aria-expanded='false'
                        aria-controls='collapse2-11'
                      >
                        What are the potential use cases and applications for a
                        private LLM?
                      </button>
                    </div>
                    <div
                      id='collapse2-11'
                      className='collapse pr-7'
                      aria-labelledby='heading2-11'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        A private LLM (Large Language Model) can have a wide
                        range of potential use cases and applications within an
                        organization. Some of these include: Knowledge
                        Management: A private LLM can help with organizing and
                        managing vast amounts of information. It can
                        automatically sift through documents, emails, and
                        databases to extract valuable insights and make them
                        easily accessible to employees. This enables faster
                        decision-making and access to critical knowledge within
                        the organization. Customer Support: By leveraging
                        natural language processing capabilities, a private LLM
                        can handle customer queries and support requests. It can
                        provide personalized and contextually relevant
                        responses, improving the overall customer experience and
                        reducing the workload on support teams.
                        <b>Content Generation</b>: Private LLMs can assist in
                        generating content such as blog posts, articles, or
                        reports. They can help with tasks like summarizing
                        information, generating creative ideas, or even
                        translating text into different languages. This
                        streamlines content creation processes and enables
                        faster and more efficient production.
                        <b>Data Analysis</b>: With its ability to understand and
                        process text, a private LLM can assist in data analysis
                        tasks. It can extract insights from unstructured data
                        sources, perform sentiment analysis, or identify
                        patterns and trends within large datasets. This aids in
                        making data-driven decisions and identifying business
                        opportunities.
                        <b>Workflow Automation</b>: By integrating a private LLM
                        into existing workflows, organizations can automate
                        repetitive tasks. For example, the LLM can automatically
                        categorize and route incoming emails, process support
                        tickets, or extract relevant information from documents.
                        This boosts productivity by reducing manual effort and
                        streamlining processes.
                        <b>Compliance and Risk Management</b>: A private LLM can
                        assist in ensuring compliance with regulations and
                        managing risks within an organization. It can analyze
                        legal documents, contracts, or privacy policies, flag
                        potential issues, and provide guidance on adherence to
                        relevant laws and regulations.
                        <b>Training and Education</b>: Private LLMs can be used
                        in training programs and educational initiatives. They
                        can provide interactive learning experiences, answer
                        questions, and deliver personalized content to enhance
                        knowledge retention and skills development. These are
                        just a few examples of the potential use cases for a
                        private LLM. The versatility of these models allows
                        organizations to adapt and customize them according to
                        their specific needs, empowering employees and improving
                        overall operational efficiency.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-12'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-12'
                        aria-expanded='false'
                        aria-controls='collapse2-12'
                      >
                        Can a private LLM be deployed on-premises or is it
                        cloud-based?
                      </button>
                    </div>
                    <div
                      id='collapse2-12'
                      className='collapse pr-7'
                      aria-labelledby='heading2-12'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        A private LLM (Large Language Model) can be deployed
                        either on-premises or in the cloud, depending on the
                        organization's preferences and requirements. On-premises
                        deployment means that the LLM infrastructure is set up
                        and hosted within the organization's own data center or
                        servers. This allows for greater control over the data
                        and ensures that sensitive information remains within
                        the organization's physical boundaries. It can be a
                        suitable option for organizations that prioritize data
                        privacy and have the necessary resources and
                        infrastructure to support the LLM. Cloud-based
                        deployment, on the other hand, involves hosting the
                        private LLM on a cloud service provider's
                        infrastructure. The organization leverages the
                        provider's servers and computing resources to run the
                        LLM. This offers flexibility, scalability, and
                        potentially reduces the organization's maintenance and
                        infrastructure costs. However, it's important to
                        carefully consider the security and privacy measures
                        provided by the chosen cloud provider to ensure data
                        protection. The decision between on-premises and cloud
                        deployment depends on factors such as data privacy
                        requirements, security considerations, available
                        resources, and the organization's overall IT strategy.
                        Both options have their advantages and considerations,
                        and organizations should assess their specific needs and
                        constraints before choosing the deployment approach for
                        their private LLM.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-13'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-13'
                        aria-expanded='false'
                        aria-controls='collapse2-13'
                      >
                        What support and maintenance options are available for a
                        private LLM?
                      </button>
                    </div>
                    <div
                      id='collapse2-13'
                      className='collapse pr-7'
                      aria-labelledby='heading2-13'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        When it comes to support and maintenance for a private
                        LLM (Large Language Model), organizations have a few
                        options to consider. These options ensure that the LLM
                        continues to function effectively and address any issues
                        that may arise.
                        <b>Vendor Support</b>: If the private LLM is obtained
                        from a vendor or provider, they typically offer support
                        services. This can include technical assistance,
                        troubleshooting, and access to their expertise. The
                        vendor's support team can help resolve any issues or
                        concerns related to the LLM's performance or
                        functionality.
                        <b>In-house Support</b>: Organizations can allocate
                        their own internal resources, such as an IT team or
                        dedicated support staff, to handle the maintenance and
                        support of the private LLM. This allows for more direct
                        control and customization of support services according
                        to the organization's specific needs.
                        <b>Community Support</b>: If the private LLM is based on
                        an open-source framework, there may be an active
                        community of developers and users who can provide
                        support. Online forums, discussion boards, and user
                        groups can serve as valuable resources for
                        troubleshooting, sharing best practices, and finding
                        solutions to common issues.
                        <b>Maintenance Contracts</b>: Some organizations may opt
                        to enter into maintenance contracts with external
                        service providers. These contracts ensure ongoing
                        support, regular updates, and maintenance of the private
                        LLM. The service provider takes care of keeping the LLM
                        up-to-date, addressing any technical issues, and
                        providing assistance when needed. The choice of support
                        and maintenance options depends on the organization's
                        resources, expertise, and specific requirements. It's
                        important to consider factors such as response times,
                        availability of updates, and the level of technical
                        support needed to ensure the smooth operation of the
                        private LLM over time.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-14'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-14'
                        aria-expanded='false'
                        aria-controls='collapse2-14'
                      >
                        What is the cost structure associated with implementing
                        and using a private LLM?
                      </button>
                    </div>
                    <div
                      id='collapse2-14'
                      className='collapse pr-7'
                      aria-labelledby='heading2-14'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        The cost structure associated with implementing and
                        using a private LLM (Large Language Model) can vary
                        depending on several factors. Here are some key
                        considerations:
                        <b>Development and Deployment Costs</b>: These costs
                        include the initial development or acquisition of the
                        private LLM, which may involve hiring AI experts,
                        licensing fees, or purchasing hardware if deploying
                        on-premises. The complexity and customization
                        requirements of the LLM can influence these costs.
                        <b>Infrastructure Costs</b>: If the private LLM is
                        cloud-based, organizations may need to consider the
                        ongoing costs of cloud services, such as storage,
                        compute resources, and network bandwidth. These costs
                        can vary based on usage and the selected cloud provider.
                        <b>Maintenance and Updates</b>: Regular maintenance and
                        updates are necessary to keep the private LLM
                        functioning optimally and secure. These costs can
                        involve internal resources or external support,
                        depending on the organization's approach.
                        <b>Training Data</b>: Training an LLM requires a
                        substantial amount of data, and acquiring or generating
                        high-quality training data can incur costs. The
                        availability and quality of existing datasets, as well
                        as data preparation and cleaning efforts, can impact
                        these costs.
                        <b>Support and Service Costs</b>: If organizations
                        choose to rely on external vendors or support services,
                        there may be ongoing costs associated with technical
                        support, consulting, or maintenance contracts.
                        <b>Scalability and Performance</b>: As usage and demand
                        for the private LLM grow, organizations might need to
                        invest in additional resources to ensure scalability and
                        high performance. This could involve scaling up
                        infrastructure, enhancing network capabilities, or
                        optimizing the LLM's configuration. It's important to
                        note that the cost structure for a private LLM can vary
                        significantly depending on factors such as the size of
                        the organization, the complexity of the LLM
                        implementation, the level of customization required, and
                        the chosen deployment model. It is advisable for
                        organizations to conduct a thorough analysis of their
                        specific needs and consider all the associated costs
                        before implementing a private LLM.
                      </div>
                    </div>
                  </div>
                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-15'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-15'
                        aria-expanded='false'
                        aria-controls='collapse2-15'
                      >
                        How does a private LLM compare to other AI solutions in
                        terms of performance and scalability?
                      </button>
                    </div>
                    <div
                      id='collapse2-15'
                      className='collapse pr-7'
                      aria-labelledby='heading2-15'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        In terms of performance and scalability, a private LLM
                        (Large Language Model) can offer certain advantages
                        compared to other AI solutions. Here's a closer look:
                        <b>Performance</b>: Private LLMs are designed to handle
                        text-based tasks with high accuracy and efficiency. They
                        excel at understanding and generating human-like text,
                        making them valuable for various applications such as
                        answering queries, summarizing information, or
                        generating reports. With proper training and
                        fine-tuning, private LLMs can provide highly relevant
                        and context-aware responses.
                        <b>Scalability</b>: Private LLMs can be scaled up to
                        accommodate increasing workloads and growing demands. By
                        leveraging additional computational resources and
                        optimizing the infrastructure, organizations can enhance
                        the performance and responsiveness of the private LLM.
                        This scalability allows the system to handle a larger
                        volume of queries or process more extensive datasets,
                        enabling organizations to expand their AI capabilities
                        as needed.
                        <b>Customization</b>: Private LLMs can be trained and
                        tailored to specific domains, industries, or
                        organizational needs. This customization enables
                        organizations to fine-tune the LLM's performance for
                        their specific use cases, making it more accurate and
                        effective in addressing their unique challenges. By
                        incorporating company-specific data and knowledge, a
                        private LLM can provide more relevant and specialized
                        insights compared to generic AI solutions.
                        <b>Data Privacy</b>: One significant advantage of a
                        private LLM is enhanced data privacy. As the name
                        suggests, a private LLM operates within the
                        organization's infrastructure, ensuring that sensitive
                        data remains within their control and complies with
                        their privacy policies. This level of control over data
                        reduces the risks associated with sharing sensitive
                        information externally and provides peace of mind
                        regarding data privacy and confidentiality.
                        <b>Integration</b>: Private LLMs can be integrated into
                        existing systems and workflows within the organization,
                        allowing for seamless collaboration between the AI
                        solution and other software or applications. This
                        integration facilitates a smoother adoption process and
                        ensures that the private LLM can leverage existing data
                        sources, knowledge repositories, and infrastructure,
                        further enhancing its performance and effectiveness.
                        While the performance and scalability of a private LLM
                        can be highly beneficial, it's important to consider the
                        specific requirements and constraints of your
                        organization. Factors such as available resources,
                        expertise, and budget should be evaluated when
                        determining the most suitable AI solution for your
                        needs.
                      </div>
                    </div>
                  </div>

                  <div className='border-bottom overflow-hidden'>
                    <div className='mb-0 border-bottom-0' id='heading2-16'>
                      <button
                        className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                        type='button'
                        data-toggle='collapse'
                        data-target='#collapse2-16'
                        aria-expanded='false'
                        aria-controls='collapse2-16'
                      >
                        How to get started ?
                      </button>
                    </div>
                    <div
                      id='collapse2-16'
                      className='collapse pr-7'
                      aria-labelledby='heading2-16'
                      data-parent='#accordionExample'
                    >
                      <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                        When it comes to implementing a PrivateGPT solution in
                        your company, several factors come into play, and the
                        approach can vary based on your specific requirements.
                        Here are three scenarios to consider:
                        <b>Self-Service Product</b>: Finding a self-service
                        product that fully satisfies your needs can be
                        challenging at present. While some digital products
                        incorporate AI elements, it's rare to find solutions
                        that seamlessly and securely cover various knowledge
                        sources tailored to each use case. Although there are
                        specific use cases available, they currently remain
                        exceptions.
                        <b>Internal Solution Development</b>: Another option is
                        to develop a solution internally using open-source
                        initiatives and products gaining traction in the market.
                        Some organizations provide support to help your internal
                        developers implement these products. However, this
                        approach requires a strong technical team with expertise
                        in Artificial Intelligence. Low-code and no-code
                        solutions are not yet capable of handling complex
                        corporate use cases and advanced security and privacy
                        requirements.
                        <b>Collaborating with a Development Partner</b>: A
                        hybrid approach involves working with a development
                        partner, which is the path taken by many organizations.
                        In this case, PrivateGPT is treated as a hybrid between
                        a service and a product. Leveraging accumulated
                        knowledge, use cases, and ongoing projects, a
                        development partner can accelerate the process of
                        defining, developing, and implementing custom solutions.
                        This approach significantly reduces the time between
                        defining requirements and delivering usable prototypes
                        within weeks. From there, a period of iteration and
                        fine-tuning follows, but the overall timeframe remains
                        relatively short. By considering these scenarios and
                        assessing factors such as the type of LLM, your
                        company's databases and knowledge base, accessibility,
                        privacy, and security needs, you can determine the most
                        suitable path for implementing a PrivateGPT solution
                        that aligns with your organization's unique
                        requirements.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pt-7 pt-lg-18 bg-default pb-2 pb-lg-24'>
        <div className='container'>
          <div className='row pb-lg-6 justify-content-center'>
            <div className='col-xxl-8 col-xl-9 col-lg-10 '>
              <h2 className='font-size-9 text-center mb-5 text-primary'>Contact Us</h2>
              <p className='text-default-color font-size-6 px-5 px-md-10 px-lg-15 px-xl-24 px-xxl-22 text-center'>
                <strong>
                  Does PrivateGPT sound useful for your organization?
                </strong>
              </p>
              <p className='text-default-color font-size-4 px-5 px-md-10 px-lg-15 px-xl-24 pb-10 px-xxl-22 text-center'>
                We are currently rolling out PrivateGPT solutions to selected
                companies and institutions worldwide. Submit your application
                and let us know about your needs and ideas, and we'll get in
                touch if we can help you.
              </p>
              <div className='row pb-lg-6 justify-content-center'>
                <div className='col-xxl-10 col-xl-10 col-lg-10 '>
                  <div className='bg-white px-9 pt-9 pb-7 shadow-8 rounded-4 contactBorder'>
                    <form>
                      <div className='row'>
                        <div className='col-12 mb-7'>
                          <label
                            
                            className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'
                          >
                            Your Name
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Jhon Doe'
                            value={name}
                            onChange={(e) => setname(e.target.value)}
                          />
                        </div>
                        <div className='col-lg-6 mb-7'>
                          <label
                            
                            className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'
                          >
                            E-mail
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='example@gmail.com'
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                        <div className='col-lg-6 mb-7'>
                          <label
                            
                            className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'
                          >
                            Subject
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Special contract'
                            value={subject}
                            onChange={(e) => setsubject(e.target.value)}
                          />
                        </div>
                        <div className='col-lg-12 mb-7'>
                          <label
                            className='font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset'
                          >
                            Message
                          </label>
                          <textarea
                            name=''
                            id=''
                            placeholder='Type your message'
                            className='form-control h-px-144'
                            value={message}
                            onChange={(e) => setmessage(e.target.value)}
                          ></textarea>
                        </div>
                        <div className='col-lg-12 pt-4'>
                          <button
                            type='button'
                            className='btn btn-primary text-uppercase w-100 h-px-48'
                            onClick={(e) => {
                              e.preventDefault();
                              sendMail();
                            }}
                          >
                            Send Now
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className='mt-8'>
                      <h3 className='font-size-4'>Contact Information</h3>
                      <div className='media mb-2'>
                        <div className='mr-6'>
                          <i className='fas fa-phone-alt mt-2'></i>
                        </div>
                        <p className='font-size-4 mb-0'>
                          <a
                            className='mb-0 font-size-4 fontBold400 '
                            href='tel:+917972869198'
                          >
                            +91(INDIA)-7972869198
                          </a>
                        </p>
                      </div>
                      <div className='media mb-2'>
                        <div className='mr-6'>
                          <i className='fas fa-envelope mt-2'></i>
                        </div>
                        <p className='font-size-4 mb-0'>
                          <a
                            className='mb-0 font-size-4 mb-2 fontBold400'
                            href='mailto:client.success@sigmatext.ai'
                          >
                            client.success@sigmatext.ai
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Private;
