import {
  BACKEND_URL,
  CONVERSATION_DATA,
  GUEST_FALSE,
  LOGIN_FAILED,
  LOGIN_FAILED_GUEST,
  LOGIN_LOADING,
  LOGIN_RESET,
  LOGIN_SUCCESS,
  LOGIN_SUCCESS_GUEST,
  NEXTCLOUD_UPDATE,
} from "../Action/types";
import axios from "axios";
import setAuthToken from "../util/setAuthToken";

export const login = (email, password) => async (dispatch) => {
  dispatch({
    type: LOGIN_LOADING,
    payload: true,
  });
  const config = {
    "Content-Type": "application/json",
  };
  const body = { email, password };
  await axios({
    method: "GET",
    url:
      BACKEND_URL +
      "auth/login?email=" +
      email +
      "&password=" +
      password +
      "&login_type=CUSTOM",
    data: body,
    headers: config,
  })
    .then((response) => {
      if (response.data.success) {
        sessionStorage.setItem("user_type", "CUSTOM");
        dispatch({
          type: LOGIN_LOADING,
          payload: false,
        });
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response.data,
        });
        dispatch(dmsStatus());
      } else {
        dispatch({
          type: LOGIN_FAILED,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      if (error.hasOwnProperty("response")) {
        if (error.response.status === 401) {
          sessionStorage.clear();
          dispatch({ type: LOGIN_RESET });
        }
      }
      dispatch({
        type: LOGIN_FAILED,
        payload: {
          message: error.hasOwnProperty("response")
            ? error.response.data.hasOwnProperty("error_message")
              ? error.response.data.error_message
              : "Please try again later!"
            : "Please try again later!",
        },
      });
      setTimeout(() => {
        dispatch({
          type: LOGIN_RESET,
        });
      }, 500);
    });
};

export const register =
  (email, password, first_name, last_name) => async (dispatch) => {
    dispatch({
      type: LOGIN_LOADING,
      payload: true,
    });
    const config = {
      "Content-Type": "application/json",
    };
    const body = {
      email,
      password,
      first_name,
      last_name,
      login_type: "CUSTOM",
    };
    await axios({
      method: "POST",
      url: BACKEND_URL + "auth/register",
      data: body,
      headers: config,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: LOGIN_LOADING,
            payload: false,
          });
          dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data,
          });
        } else {
          dispatch({
            type: LOGIN_FAILED,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            dispatch({ type: LOGIN_RESET });
          }
        }
        dispatch({
          type: LOGIN_FAILED,
          payload: {
            message: error.hasOwnProperty("response")
              ? error.response.data.hasOwnProperty("error_message")
                ? error.response.data.error_message
                : "Please try again later!"
              : "Please try again later!",
          },
        });
        setTimeout(() => {
          dispatch({
            type: LOGIN_RESET,
          });
        }, 500);
      });
  };

export const loginTemp = () => async (dispatch) => {
  dispatch({
    type: LOGIN_LOADING,
    payload: true,
  });
  const config = {
    "Content-Type": "application/json",
  };
  const body = {};
  await axios({
    method: "GET",
    url: BACKEND_URL + "auth/login?login_type=GUEST",
    data: body,
    headers: config,
  })
    .then((response) => {
      if (response.data.success) {
        sessionStorage.setItem("user_type", "GUEST");
        setAuthToken("Bearer " + response.data.access_token);
        dispatch({
          type: LOGIN_LOADING,
          payload: false,
        });
        dispatch({
          type: LOGIN_SUCCESS_GUEST,
          payload: response.data,
        });
      } else {
        dispatch({
          type: LOGIN_FAILED_GUEST,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      if (error.hasOwnProperty("response")) {
        if (error.response.status === 401) {
          sessionStorage.clear();
          dispatch({ type: LOGIN_RESET });
        }
      }
      dispatch({
        type: LOGIN_FAILED_GUEST,
        payload: {
          message: error.hasOwnProperty("response")
            ? error.response.data.hasOwnProperty("error_message")
              ? error.response.data.error_message
              : "Please try again later!"
            : "Please try again later!",
        },
      });
      setTimeout(() => {
        dispatch({
          type: LOGIN_RESET,
        });
      }, 500);
    });
};

export const checkToken = () => async (dispatch) => {
  dispatch({
    type: LOGIN_LOADING,
    payload: true,
  });
  const config = {
    "Content-Type": "application/json",
  };
  await axios({
    method: "POST",
    url: BACKEND_URL + "auth/token",
    data: { refresh_token: sessionStorage.refresh_token },
    headers: config,
  })
    .then(async (response) => {
      if (response.data.success) {
        await dispatch({
          type: LOGIN_LOADING,
          payload: false,
        });
        if (sessionStorage.user_type === "GUEST") {
          await dispatch({
            type: LOGIN_SUCCESS_GUEST,
            payload: {
              refresh_token: response.data.refresh_token,
              access_token: response.data.access_token,
            },
          });
        } else {
          await dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              refresh_token: response.data.refresh_token,
              access_token: response.data.access_token,
              nextcloud_logged_in_data: JSON.parse(
                sessionStorage.getItem("nextCloud")
              ),
            },
          });
        }
        dispatch(dmsStatus());
      } else {
        dispatch({
          type: LOGIN_FAILED_GUEST,
          payload: response.data,
        });
      }
    })
    .catch((error) => {
      if (error.hasOwnProperty("response")) {
        if (error.response.status === 401) {
          sessionStorage.clear();
          dispatch({ type: LOGIN_RESET });
        }
      }
    });
};

export const checkTokenGet = () => async (dispatch) => {
  await setAuthToken("Bearer " + sessionStorage.getItem("token"));
  dispatch({
    type: LOGIN_LOADING,
    payload: true,
  });
  const config = {
    "Content-Type": "application/json",
  };
  await axios({
    method: "GET",
    url: BACKEND_URL + "auth/token",
    headers: config,
  })
    .then(async (response) => {
      if (response.data.success) {
        await dispatch({
          type: LOGIN_LOADING,
          payload: false,
        });
        if (sessionStorage.user_type === "GUEST") {
          await dispatch({
            type: LOGIN_SUCCESS_GUEST,
            payload: {
              refresh_token: sessionStorage.refresh_token,
              access_token: sessionStorage.token,
            },
          });
        } else {
          await dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              refresh_token: sessionStorage.refresh_token,
              access_token: sessionStorage.token,
              nextcloud_logged_in_data: JSON.parse(
                sessionStorage.getItem("nextCloud")
              ),
            },
          });
        }
        dispatch(dmsStatus());
      } else {
        if (sessionStorage.user_type === "GUEST") {
          dispatch({ type: GUEST_FALSE });
          dispatch({
            type: LOGIN_FAILED_GUEST,
            payload: {
              refresh_token: sessionStorage.refresh_token,
              access_token: sessionStorage.token,
            },
          });
        } else {
          dispatch({
            type: LOGIN_FAILED,
            payload: {
              refresh_token: sessionStorage.refresh_token,
              access_token: sessionStorage.token,
            },
          });
        }
        setTimeout(() => {
          dispatch({ type: LOGIN_RESET });
        }, 10);
      }
    })
    .catch((error) => {
      if (sessionStorage.getItem("user_type") === "GUEST") {
        dispatch({ type: GUEST_FALSE });
        setTimeout(() => {
          dispatch({ type: LOGIN_RESET });
        }, 10);
      } else {
        dispatch({ type: GUEST_FALSE });
        setTimeout(() => {
          dispatch({ type: LOGIN_RESET });
        }, 10);
      }
      sessionStorage.clear();
      setTimeout(() => {
        dispatch({ type: LOGIN_RESET });
      }, 10);
    });
};

// api to check nextcloud status https://core.sigmatext.ai/nextcloud/status get request
export const dmsStatus =
  (trigger = false) =>
  async (dispatch) => {
    dispatch({
      type: LOGIN_LOADING,
      payload: true,
    });
    const config = {
      "Content-Type": "application/json",
    };
    await axios({
      method: "GET",
      url: BACKEND_URL + "nextcloud/status",
      headers: config,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: NEXTCLOUD_UPDATE,
            payload: response.data.nextcloud_logged_in_data,
          });
          sessionStorage.setItem(
            "nextCloud",
            JSON.stringify(response.data.nextcloud_logged_in_data)
          );
          if (trigger) {
            if (!response.data.nextcloud_logged_in_data.is_logged_in) {
              setTimeout(() => {
                dispatch(dmsStatus(true));
              }, 3000);
            }
          }
        } else {
          dispatch({
            type: LOGIN_FAILED,
            payload: {
              message: response.data.hasOwnProperty("error_message")
                ? response.data.error_message
                : "Please try again later!",
            },
          });
        }
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          if (error.response.status === 401) {
            sessionStorage.clear();
            dispatch({ type: LOGIN_RESET });
          }
        }
        dispatch({
          type: LOGIN_FAILED,
          payload: {
            message: error.hasOwnProperty("response")
              ? error.response.data.hasOwnProperty("error_message")
                ? error.response.data.error_message
                : "Please try again later!"
              : "Please try again later!",
          },
        });
      });
  };

export const signout = (email, password) => async (dispatch) => {
  dispatch({
    type: LOGIN_LOADING,
    payload: true,
  });
  const config = {
    "Content-Type": "application/json",
  };
  const body = { email, password };
  await axios({
    method: "GET",
    url: BACKEND_URL + "auth/logout",
    data: body,
    headers: config,
  })
    .then((response) => {
      if (response.data.success) {
        sessionStorage.clear();
        dispatch({ type: LOGIN_RESET });
        dispatch({
          type: CONVERSATION_DATA,
          payload: {},
        });
      } else {
      }
    })
    .catch((error) => {});
};
