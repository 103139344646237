import { Input, Table } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';

const Prompts = () => {
  const columns = [
    {
      title: 'Category',
      dataIndex: 'prompt',
      key: 'prompt',
      render: (text, row, index) => {
        if (index % 4 === 0) {
          return {
            children: text,
            props: {
              rowSpan: 4,
            },
          };
        }
        if (index % 4 === 1) {
          return {
            props: {
              rowSpan: 0,
            },
          };
        }
        if (index % 4 === 2) {
          return {
            props: {
              rowSpan: 0,
            },
          };
        }
        if (index % 4 === 3) {
          return {
            props: {
              rowSpan: 0,
            },
          };
        }
        if (index % 4 === 4) {
          return {
            props: {
              rowSpan: 0,
            },
          };
        }
        if (index % 4 === 5) {
          return {
            props: {
              rowSpan: 0,
            },
          };
        }
      },
    },
    {
      title: 'Prompt Example',
      dataIndex: 'response',
      key: 'response',
    },
  ];

  const [dataset, setdataset] = useState([]);
  const [search, setsearch] = useState('');

  useEffect(() => {
    var data = [
      {
        prompt: 'Be specific',
        response:
          'Provide detailed information from the PDF document about [topic], including key insights, statistics, and examples.',
      },
      {
        prompt: 'Be specific',
        response:
          "Extract specific data points related to [topic] from the Excel file, such as [specific data points you're interested in].",
      },
      {
        prompt: 'Be specific',
        response:
          'Retrieve relevant information from the Word document on [topic], focusing on the sections discussing [specific section or subsection].',
      },
      {
        prompt: 'Be specific',
        response:
          'Fetch important facts fromthe text file about [topic], particularly those mentioned in [specific section or paragraph].',
      },
      {
        prompt: 'Use targeted prompts',
        response:
          'Retrieve key information from the PDF document about [topic].',
      },
      {
        prompt: 'Use targeted prompts',
        response: 'Fetch relevant data from the Excel spreadsheet on [topic].',
      },
      {
        prompt: 'Use targeted prompts',
        response:
          'Extract specific details from the Word file related to [topic].',
      },
      {
        prompt: 'Use targeted prompts',
        response: 'Find the necessary facts inthe text file about [topic].',
      },
      {
        prompt: 'Highlight key terms',
        response:
          'Retrieve information about [specific keyword] from the PDF document on [topic].',
      },
      {
        prompt: 'Highlight key terms',
        response:
          'Fetch data related to [specific keyword] from the Excel file.',
      },
      {
        prompt: 'Highlight key terms',
        response:
          'Extract details about [specific keyword] from the Word document on [topic].',
      },
      {
        prompt: 'Highlight key terms',
        response:
          'Find relevant facts about [specific keyword] inthe text file.',
      },
      {
        prompt: 'Provide additional instructions',
        response:
          'Retrieve key information from the PDF document about [topic], specifically in the section labeled [section name].',
      },
      {
        prompt: 'Provide additional instructions',
        response:
          'Fetch relevant data from the Excel spreadsheet on [topic], focusing on the column labeled [column name].',
      },
      {
        prompt: 'Provide additional instructions',
        response:
          'Extract specific details from the Word document related to [topic], particularly in the subsection titled [subsection name].',
      },
      {
        prompt: 'Provide additional instructions',
        response:
          'Find the necessary facts inthe text file about [topic], particularly in the paragraph that discusses [specific paragraph].',
      },
      {
        prompt: 'Utilize context from the document',
        response:
          'Retrieve key information from the PDF document about [topic], particularly in the table labeled [table name].',
      },
      {
        prompt: 'Utilize context from the document',
        response:
          'Fetch relevant data from the Excel spreadsheet on [topic], focusing on the chart in the sheet titled [sheet name].',
      },
      {
        prompt: 'Utilize context from the document',
        response:
          'Extract specific details from the Word document related to [topic], particularly in the section that includes [specific section].',
      },
      {
        prompt: 'Utilize context from the document',
        response:
          'Find the necessary facts inthe text file about [topic], particularly in the section that mentions [specific section].',
      },
      {
        prompt: 'Iterate and refine',
        response:
          'Refine the search to extract more specific information from the PDF document about [topic], such as [additional details].',
      },
      {
        prompt: 'Iterate and refine',
        response:
          'Adjust the query to fetch more accurate data from the Excel file on [topic], focusing on [specific criteria].',
      },
      {
        prompt: 'Iterate and refine',
        response:
          'Iterate the search to find the necessary facts in the text file about [topic], focusing on the section that discusses [specific section].',
      },
      {
        prompt: 'Iterate and refine',
        response:
          'Rephrase the prompt to extract the desired details from the Word document related to [topic], specifically in the subsection titled [subsection name].',
      },
    ];
    data = data.filter(
      (item) =>
        item.prompt.toLowerCase().indexOf(search.toLowerCase()) > -1 
    );
    setdataset(data);
  }, [search]);

  return (
    <div>
      <section className='pt-15 pt-lg-30 pb-7 pb-lg-10 bg-default'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div
              className='col-xxl-8 col-lg-9 col-md-10 aos-init aos-animate'
              data-aos='fade-in'
              data-aos-duration='1000'
              data-aos-once='true'
            >
              <div className='section-title text-center mb-4 mb-lg-5 mb-lg-4 pb-lg-4 pb-0 '>
                <h2
                  className='mb-6 text-primary'
                  style={{ lineHeight: '35px' }}
                >
                  Prompts
                </h2>
                
              </div>
            </div>
          </div>
          <div className='row pb-lg-10 justify-content-center'>
            <div
              className='col-xl-12 col-lg-12 col-md-12'
              data-aos='fade-left'
              data-aos-duration='800'
              data-aos-once='true'
            >
              <div className='pl-lg-10 pl-0 d-flex flex-column justify-content-center h-100 pt-lg-0 pt-15 pr-md-10 pr-xl-10 pr-xxl-10 pr-0 '>
                <div className='row mx-0 mb-2'>
                  <div className='col-3 text-left mb-0 p-0 m-0'>
                    <Input
                      placeholder='Search'
                      value={search}
                      onChange={(e) => setsearch(e.target.value)}
                    />
                  </div>
                </div>
                <Table
                  columns={columns}
                  bordered
                  dataSource={dataset}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Prompts;
