import {
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGIN_RESET,
  LOGIN_LOADING,
  LOGIN_SUCCESS_GUEST,
  GUEST_FALSE,
  LOGIN_FAILED_GUEST,
  NEXTCLOUD_UPDATE,
} from "../Action/types";

const initialState = {
  token: sessionStorage.getItem("token"),
  isAuthenticated: false,
  loading: false,
  isError: false,
  isErrorType: null,
  errMessage: null,
  isGuest: false,
  nextCloud: localStorage.getItem("nextCloud")
    ? JSON.parse(localStorage.getItem("nextCloud"))
    : {},
  refresh_token: sessionStorage.getItem("refresh_token"),
};

function authReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case NEXTCLOUD_UPDATE:
      return {
        ...state,
        nextCloud: payload,
      };
    case LOGIN_RESET:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: true,
        isError: false,
        isErrorType: null,
        errMessage: null,
        isGuest: true,
        nextCloud: {},
        refresh_token: null,
      };
    case LOGIN_SUCCESS:
      sessionStorage.setItem("token", payload.access_token);
      sessionStorage.setItem("refresh_token", payload.refresh_token);
      localStorage.setItem("token", payload.access_token);
      localStorage.setItem("refresh_token", payload.refresh_token);
      sessionStorage.setItem(
        "nextCloud",
        JSON.stringify(payload.nextcloud_logged_in_data)
      );
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        token: payload.access_token,
        refresh_token: payload.refresh_token,
        isGuest: false,
      };
    case LOGIN_SUCCESS_GUEST:
      sessionStorage.setItem("token", payload.access_token);
      sessionStorage.setItem("refresh_token", payload.refresh_token);
      localStorage.setItem("token", payload.access_token);
      localStorage.setItem("refresh_token", payload.refresh_token);
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        token: payload.access_token,
        isGuest: true,
        refresh_token: payload.refresh_token,
      };
    case GUEST_FALSE:
      return {
        ...state,
        isGuest: false,
      };
    case LOGIN_FAILED:
      sessionStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        isError: true,
        isErrorType: "LOGIN_FAILED",
        errMessage: payload.message,
      };
    case LOGIN_FAILED_GUEST:
      sessionStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        isError: true,
        isErrorType: "LOGIN_FAILED",
        errMessage: payload.message,
      };
    default:
      return state;
  }
}

export default authReducer;
