import Search from 'antd/es/input/Search';
import React, { useEffect, useState } from 'react';
const data = [
  {
    question: 'My files are not getting processed',
    answer:
      'Please check if all files can be edited and opened by a third party software. E.g. for excels , enable editing for files downoaded from net. Similarly there should not be any file that is password protected',
  },
  {
    question: 'How do I chat / get information only from a particular folder',
    answer:
      "Click on the folder you want to search . The results will be from that folder only . If you don't select any folder, then it will search from all your folders",
  },
  {
    question: 'It say\'s "Processing your document" and chat is struck.',
    answer:
      'When you upload a large folder or a file, it may take some time for us to process your files, so please bear with us. This is not an error and it will go away automatically once your files are processed.',
  },
  {
    question: 'My folder got uploaded but I am struct on the same screen',
    answer:
      'Ideally you should be redirected to your dashboard screen. If not, please click on dashboard link at the top to see the uploaded files',
  },
  {
    question: 'What file formats can I upload ?',
    answer:
      'We support a wide range of file formats, including PDFs, Word documents, Excel spreadsheets, PowerPoint presentations, images (JPEG, PNG, etc.), and more. All your files must have editing rights',
  },
  {
    question: 'How do I upload a document to the system?',
    answer:
      'You can upload either single file or an entire folder. The files can also be ingested via a url.',
  },
  {
    question: 'Do you support chat with youtube / videos ?',
    answer: 'Yes, just enter the URL of the video and you will be good to go',
  },
  {
    question: 'Can I extract text from scanned documents or images?',
    answer:
      'Yes, we use Optical Character Recognition (OCR) technology to extract text from scanned documents and images, making them searchable and editable.',
  },
  {
    question: 'What types of information is extracted from documents?',
    answer:
      'We extract only the text from the document. No meta data is extracted by default',
  },
  {
    question: 'Is the extracted information editable?',
    answer: 'No',
  },
  {
    question: 'How does the search functionality work?',
    answer:
      'We provide a powerful search feature that allows you to search for documents based on keywords only. Simply enter your search query, and the system will return relevant results.',
  },
  {
    question: 'Can I set access controls and permissions for documents?',
    answer:
      'This feature does not exist in the demo version. However, this can be added in the custom version that you will get .',
  },
  {
    question: 'Is there a version control system for documents?',
    answer: 'No, just delete the document you want to change and re-upload it',
  },
  {
    question: 'How can I integrate this with other applications?',
    answer:
      'We offer integration options with popular software and platforms such as Microsoft Office, Google Workspace, and more. Please contact our support team for specific integration details.',
  },
  {
    question: 'What security measures are in place to protect my documents?',
    answer:
      'We employ robust security measures, including encryption, authentication, and access controls, to safeguard your documents and data.',
  },
  {
    question: 'How do I get technical support or assistance with the tool ?',
    answer:
      'You can contact our customer support team through email for assistance with any issues or questions.',
  },
  {
    question: 'Is training available for using the system effectively?',
    answer:
      'This is designed to be easy to use solution . Please let us know your doubts over an email and we will be happy to help',
  },
  {
    question:
      "Can I customize the solution to match my organization's specific needs?",
    answer:
      "Depending on your requirements, we can offer customization options to tailor the solution to your organization's unique workflow and processes.",
  },
  {
    question: 'What is the maximum file size that I can upload?',
    answer: 'No size limit',
  },
  {
    question: 'Can I upload multiple files at once?',
    answer: 'No , one file upload at a time',
  },
  {
    question: 'Can I upload folders?',
    answer: 'Yes',
  },
  {
    question: 'What file formats are not supported?',
    answer:
      'Zip files, google drive links & hand written scanned ones are not supported yet',
  },
  {
    question: 'What happens if I try to upload a file that is not supported?',
    answer:
      'You will get a message stating that file format is not supported yet',
  },
  {
    question: 'How do I convert a file to a supported format?',
    answer: 'Use any online tool to convert it to a supported format',
  },
  {
    question: 'How can I search for specific information in my documents?',
    answer:
      'Just select the folder or that specific file and search for the relevant information using the chat feature',
  },
  {
    question: 'How can I extract data from my documents?',
    answer:
      'Just select the folder or that specific file and search for the relevant information using the chat feature',
  },
  {
    question: 'How can I collaborate on documents with others?',
    answer: 'Available only in custom deployment',
  },
  {
    question: 'Who has access to my documents?',
    answer: 'Only the backend team can access to your documents on request',
  },
  {
    question: 'How can I control who has access to my documents?',
    answer: 'This feature is available in custom deployment only',
  },
  {
    question: 'How can I audit access to my documents?',
    answer: 'This feature is available in custom deployment only',
  },
  {
    question: 'How can I recover my documents if they are lost or corrupted?',
    answer:
      'We dont have any back ups. Please maintain a separate backup for your reference',
  },
  {
    question: 'I am having trouble uploading a file. What can I do?',
    answer:
      'Check if it is a supported format. If no, please convert it to a supported format. If yes, write to us on the specific issue you are facing',
  },
  {
    question: 'I cannot find a document that I uploaded. What can I do?',
    answer: 'Contact support with specific details',
  },
  {
    question:
      'I am getting an error message when I try to access a document. What can I do?',
    answer:
      'Please check if you are using a valid file format. If the issue still persists then contact support with specific details',
  },
  {
    question: 'How do I report a bug?',
    answer: 'Send us an email and we will respond within 24 hours',
  },
];

const Private = () => {
  const [dataArray, setdataArray] = useState([]);
  const [search, setsearch] = useState('');
  useEffect(() => {
    if (search !== '') {
      setdataArray(
        data.filter((ele) => ele.question.toLowerCase().includes(search))
      );
    } else {
      setdataArray(data);
    }
  }, [search]);

  return (
    <div>
      <section className='pt-15 pt-lg-30 pb-7 pb-lg-4 bg-default'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div
              className='col-xxl-8 col-lg-9 col-md-10 aos-init aos-animate'
              data-aos='fade-in'
              data-aos-duration='1000'
              data-aos-once='true'
            >
              <div className='section-title text-center mb-10 mb-lg-12 mb-lg-10 pb-lg-9 pb-0'>
                <h1 className='font-size-9 mb-6'>Frequently asked questions</h1>
                <p className='text-default-color font-size-5 px-5 px-md-10 px-lg-15 px-xl-24 px-xxl-22'>
                  Explore our Frequently Asked Questions (FAQ) for concise
                  answers to common inquiries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pt-2 pt-lg-0 pb-lg-20 bg-default'>
        <div className='container'>
          <div className='row align-items-center justify-content-center'>
            <div
              className='col-xl-10 col-md-11'
              data-aos='fade-left'
              data-aos-duration='800'
              data-aos-once='true'
            >
              <Search
                placeholder='Search your questions'
                allowClear
                enterButton='Search'
                size='large'
                onChange={(e) => setsearch(e.target.value)}
                onSearch={() => { }}
              />
              <div className='faq-content pt-lg-4 pt-6'>
                <div
                  className='accordion rounded-10 border-green border-top-5 pl-1'
                  id='accordionExample'
                >
                  {dataArray.map((ele, index) => (
                    <div className='border-bottom overflow-hidden' key={index}>
                      <div
                        className='mb-0 border-bottom-0'
                        id={'heading2-' + (index + 1)}
                      >
                        <button
                          className='btn-reset font-size-5 font-weight-semibold text-left px-0 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2 text-primary'
                          type='button'
                          data-toggle='collapse'
                          data-target={'#collapse2-' + (index + 1)}
                          aria-expanded='true'
                          aria-controls={'#collapse2-' + (index + 1)}
                        >
                          {ele.question}
                        </button>
                      </div>
                      <div
                        id={'collapse2-' + (index + 1)}
                        className={'collapse pr-7' + (index === 0 && ' show')}
                        aria-labelledby={'heading2-' + (index + 1)}
                        data-parent='#accordionExample'
                      >
                        <div className='mt-n3 font-size-4 text-gray font-weight-normal pb-7 pr-7 pt-6'>
                          {ele.answer}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Private;
