import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { signout } from "../../Api/Auth";

const TopBar = ({
  setshowModal,
  setshowModal1,
  setshowModal2,
  isAuthenticated,
  setshowModal3,
  setOpen,
  signout,
}) => {
  const history = useHistory();
  useEffect(() => {
    setOpen(false);
    window.scrollTo(0, 0);
  }, [history.location.pathname, setOpen]);

  useEffect(() => {
    if (
      window.location.pathname === "/" ||
      window.location.pathname.includes("education")
    ) {
      sessionStorage.setItem("url_type", window.location.pathname);
    }
  }, []);

  return (
    <header className="site-header site-header--menu-right bg-default position-fixed py-7 py-xs-0 site-header--absolute shadow-sm">
      <div className="container">
        <nav className="navbar site-navbar offcanvas-active navbar-expand-lg  px-0 py-0">
          <div className="brand-logo">
            <Link
              to="#!"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (sessionStorage.getItem("url_type").includes("/education")) {
                  history.push("/education");
                } else {
                  history.push("/");
                }
              }}
            >
              <img
                src="/assets/image/logoBlack.png"
                alt=""
                className="light-version-logo default-logo"
                style={{ height: "54px" }}
              />
              <img
                src="/assets/image/logoBlack.png"
                alt=""
                className="dark-version-logo"
                style={{ height: "54px" }}
              />
            </Link>
          </div>
          <div className="collapse navbar-collapse" id="mobile-menu">
            <div className="navbar-nav-wrapper">
              <ul className="navbar-nav main-menu">
                <li
                  className={
                    "nav-item " +
                    (history.location.pathname === "/" && "active")
                  }
                >
                  <Link
                    className="nav-link"
                    to="/"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (
                        sessionStorage
                          .getItem("url_type")
                          .includes("/education")
                      ) {
                        history.push("/education");
                      } else {
                        history.push("/");
                      }
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      if (isAuthenticated) {
                        setshowModal1(true);
                      } else {
                        setshowModal(true);
                      }
                    }}
                  >
                    Pricing
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="#!"
                    onClick={(e) => {
                      e.preventDefault();
                      if (isAuthenticated) {
                        if (window.location.pathname === "/education") {
                          history.push("/education/dashboard");
                        } else {
                          history.push("/dashboard");
                        }
                      } else {
                        setshowModal(true);
                      }
                    }}
                  >
                    {isAuthenticated ? "Dashboard" : "Try Now"}
                  </Link>
                </li>
                <li
                  className={
                    "nav-item dropdown  " +
                    ((history.location.pathname === "/private-gpt" ||
                      history.location.pathname === "/LLM") &&
                      "active")
                  }
                >
                  <a
                    className="nav-link dropdown-toggle gr-toggle-arrow"
                    id="navbarDropdown"
                    href="#features"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Go Private <i className="icon icon-small-down"></i>
                  </a>
                  <ul
                    className="gr-menu-dropdown dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="drop-menu-item">
                      <Link className="" to="/private-gpt">
                        Private GPT
                      </Link>
                    </li>
                    <li className="drop-menu-item">
                      <Link className="" to="/LLM">
                        LLM
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <button
              className="d-block d-lg-none offcanvas-btn-close focus-reset"
              type="button"
              data-toggle="collapse"
              data-target="#mobile-menu"
              aria-controls="mobile-menu"
              aria-expanded="true"
              aria-label="Toggle navigation"
            >
              <i className="gr-cross-icon"></i>
            </button>
          </div>
          <div
            className={
              "header-btns header-btn-devider ml-auto pr-2 ml-lg-6 d-none d-xs-flex " +
              (isAuthenticated ? "pl-10" : "")
            }
          >
            {!isAuthenticated && (
              <Link
                className="btn btn-transparent text-uppercase font-size-3 heading-default-color focus-reset"
                to="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setshowModal(true);
                }}
              >
                Log in
              </Link>
            )}
            {isAuthenticated ? (
              // <Link
              //   className='btn btn-primary text-uppercase font-size-3'
              //   to='#!'
              //   onClick={(e) => {
              //     e.preventDefault();
              //     sessionStorage.clear();
              //     localStorage.clear();
              //     store.dispatch({ type: LOGIN_RESET });
              //   }}
              // >
              <ul className="navbar-nav main-menu">
                <li className={"nav-item dropdown text-white   "}>
                  <a
                    className="btn btn-primary text-uppercase font-size-3"
                    id="navbarDropdown"
                    href="#features"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ color: "white" }}
                  >
                    Profile <i className="icon icon-small-down"></i>
                  </a>
                  <ul
                    className="gr-menu-dropdown dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li className="drop-menu-item">
                      <Link
                        className=""
                        to="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          setshowModal3(true);
                        }}
                      >
                        Reset Password
                      </Link>
                    </li>
                    <li className="drop-menu-item">
                      <Link
                        className=""
                        to="#!"
                        onClick={(e) => {
                          e.preventDefault();
                          signout();
                        }}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            ) : (
              <Link
                className="btn btn-primary text-uppercase font-size-3"
                to="#!"
                onClick={(e) => {
                  e.preventDefault();
                  setshowModal2(true);
                }}
              >
                Sign up
              </Link>
            )}
          </div>
          <button
            className="navbar-toggler btn-close-off-canvas  hamburger-icon border-0"
            type="button"
            data-toggle="collapse"
            data-target="#mobile-menu"
            aria-controls="mobile-menu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="hamburger hamburger--squeeze js-hamburger">
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </span>
          </button>
        </nav>
      </div>
    </header>
  );
};

TopBar.propTypes = {
  isAuthenticated: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { signout })(TopBar);
